import { UnreachableCaseError } from "ts-essentials";

import {
  language_enum,
  offer_goal_enum,
  offer_type_enum,
  OfferPlatformEcommerceProductsQuery,
  platform_enum,
  translation_value_format_enum,
} from "../../../../__generated__/graphql";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../../common/platform/features";
import defaultPauseInterval from "../../lib/defaultPauseInterval";
import {
  getChangePlanDefaults,
  getCouponDefaults,
  getCustomDefaults,
  getDowngradeDefaults,
  getModifySubscriptionDefaults,
  getPauseSubscriptionDefaults,
  getProductSwapDefaults,
  getRescheduleOrderDefaults,
  getTrialExtensionDefaults,
  getUpgradeDefaults,
} from "../../lib/offerDefaults";
import { CouponOfferFormValues, OfferFormValues } from "../types";

interface GetDefaultFormValuesArgs {
  type: `${offer_type_enum}`;
  enabledLanguages: language_enum[];
  platform: platform_enum;
  goal?: offer_goal_enum;
  products?: OfferPlatformEcommerceProductsQuery["platform_ecommerce_product"];
  showBannerOverride?: boolean | undefined;
}

const showBanner = (value: boolean, override: boolean | undefined) => {
  if (override !== undefined) {
    return override;
  }
  return value;
};

const getDefaultFormValues = <T = OfferFormValues>({
  type,
  enabledLanguages,
  platform,
  products,
  goal = offer_goal_enum.retention,
  showBannerOverride = undefined,
}: GetDefaultFormValuesArgs): TranslatedForms<T> => {
  switch (type) {
    case "change_plan":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getChangePlanDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "change_plan",
            name: "",
            tags: [],
            prorate: true,
            planId: "",
            planName: null,
            couponId: "",
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            changeAt: "immediately",
            resetBillingDate: true,
            metadata: JSON.stringify([]),
            countdownTimer: true,
            confirmationConfirmButtonText: values.confirmationConfirmButtonText,
            confirmationCancelButtonText: values.confirmationCancelButtonText,
            style: "modal",
          },
        };
      }, {});

    case "coupon":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getCouponDefaults(goal, language);

        return {
          ...prev,
          [language]: {
            type: "coupon",
            name: "",
            tags: [] as string[],
            couponType: platformHasFeature(
              platform,
              PlatformFeature.CouponsPercentageDiscount,
              { defaultForCustom: true }
            )
              ? "percentage"
              : "fixed",
            amountOff: null,
            duration: "once",
            applyTo: "subscription",
            months: null,
            showBanner: showBanner(true, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            metadata: JSON.stringify([]),
            countdownTimer: true,
            endTrial: true,
            confirmationConfirmButtonText: values.confirmationConfirmButtonText,
            confirmationCancelButtonText: values.confirmationCancelButtonText,
            style: "modal",
          } as CouponOfferFormValues,
        };
      }, {});

    case "custom":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getCustomDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "custom",
            name: "",
            tags: [],
            showBanner: showBanner(true, showBannerOverride),
            headline: "",
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            metadata: JSON.stringify([]),
            countdownTimer: true,
            confirmationConfirmButtonText: values.confirmationConfirmButtonText,
            confirmationCancelButtonText: values.confirmationCancelButtonText,
            style: "modal",
          },
        };
      }, {});

    case "downgrade":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getDowngradeDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "downgrade",
            name: "",
            tags: [],
            planId: "",
            planName: null,
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            changeAt: "immediately",
            metadata: JSON.stringify([]),
            style: "modal",
          },
        };
      }, {});

    case "modify_subscription":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getModifySubscriptionDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "modify_subscription",
            name: "",
            tags: [],
            prorate: true,
            addPlans: JSON.stringify([]),
            removePlans: JSON.stringify([]),
            addAddons: JSON.stringify([]),
            removeAddons: JSON.stringify([]),
            addCustomPlans: JSON.stringify([]),
            removeCustomPlans: JSON.stringify([]),
            planId: "",
            planName: null,
            couponId: "",
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            modifyAt: "immediately",
            metadata: JSON.stringify([]),
            style: "modal",
          },
        };
      }, {});

    case "pause_subscription":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getPauseSubscriptionDefaults(
          language,
          platformHasFeature(
            platform,
            PlatformFeature.PauseSubscriptionSpecifyLength,
            { defaultForCustom: true }
          )
        );

        return {
          ...prev,
          [language]: {
            type: "pause_subscription",
            name: "",
            tags: [],
            interval: defaultPauseInterval(platform),
            intervalCount: null,
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            pauseAt: "immediately",
            metadata: JSON.stringify([]),
            option1Present: true,
            option1Type: "interval",
            option1Interval: defaultPauseInterval(platform),
            option1IntervalCount: null,
            option1Text: null,
            option1Metadata: JSON.stringify([]),
            option2Present: false,
            option2Type: "interval",
            option2Interval: defaultPauseInterval(platform),
            option2IntervalCount: null,
            option2Text: null,
            option2Metadata: JSON.stringify([]),
            option3Present: false,
            option3Type: "interval",
            option3Interval: defaultPauseInterval(platform),
            option3IntervalCount: null,
            option3Text: null,
            option3Metadata: JSON.stringify([]),
            style: "modal",
          },
        };
      }, {});

    case "product_swap":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getProductSwapDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "product_swap",
            name: "",
            tags: [] as string[],
            specificProducts: false,
            products:
              products?.map((product) => ({
                productId: product.platform_id,
                variants: product.platform_variants.map((variant) => ({
                  variantId: variant.platform_id,
                  name: variant.name,
                  value: true,
                  price: variant.price ? Number(variant.price) : 0,
                })),
              })) || [],
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            metadata: JSON.stringify([]),
            confirmationConfirmButtonText: values.confirmationConfirmButtonText,
            confirmationCancelButtonText: values.confirmationCancelButtonText,
            style: "modal",
          },
        };
      }, {});

    case "reschedule_order":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getRescheduleOrderDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "reschedule_order",
            name: "",
            tags: [],
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            skipEnabled: true,
            rescheduleEnabled: true,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            skipButtonText: values.skipButtonText,
            rescheduleButtonText: values.rescheduleButtonText,
            metadata: JSON.stringify([]),
            countdownTimer: true,
            buttonText: "",
            style: "modal",
          },
        };
      }, {});

    case "trial_extension":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getTrialExtensionDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "trial_extension",
            name: "",
            tags: [],
            days: null,
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            metadata: JSON.stringify([]),
            countdownTimer: true,
            confirmationConfirmButtonText: values.confirmationConfirmButtonText,
            confirmationCancelButtonText: values.confirmationCancelButtonText,
            style: "modal",
          },
        };
      }, {});

    case "upgrade":
      return enabledLanguages.reduce<TranslatedForms<T>>((prev, language) => {
        const values = getUpgradeDefaults(language);

        return {
          ...prev,
          [language]: {
            type: "upgrade",
            name: "",
            tags: [],
            planId: "",
            planName: null,
            showBanner: showBanner(false, showBannerOverride),
            headline: values.headline,
            content: JSON.stringify(values.content),
            contentFormat: translation_value_format_enum.lexical,
            buttonText: values.buttonText,
            changeAt: "immediately",
            metadata: JSON.stringify([]),
            style: "modal",
          },
        };
      }, {});

    default:
      throw new UnreachableCaseError(type);
  }
};

export default getDefaultFormValues;
