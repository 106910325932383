import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Product } from "./ProductSwapForm";
import MissingImagePlaceHolder from "./ui/MissingImagePlaceholder";

interface ProductSwapProductFormProps {
  selected: boolean;
  product: Product;
  onChange: (productId: string, variantId: string | null) => void;
}

const getVariantOptions = (
  product: Product,
  key: string,
  index: number,
  choice0: string | null = null,
  choice1: string | null = null
) => {
  const options: string[] = [];

  if (index === 0) {
    for (const v of product.platform_variants) {
      for (const o of v.platform_variant_options) {
        if (o.key === key) {
          options.push(o.value);
        }
      }
    }
  } else if (index === 1) {
    for (const v of product.platform_variants) {
      if (v.platform_variant_options[0]?.value === choice0) {
        if (
          v.platform_variant_options[1]?.key === key &&
          v.platform_variant_options[1]?.value
        ) {
          options.push(v.platform_variant_options[1]?.value);
        }
      }
    }
  } else if (index === 2) {
    for (const v of product.platform_variants) {
      if (
        v.platform_variant_options[0]?.value === choice0 &&
        v.platform_variant_options[1]?.value === choice1
      ) {
        if (
          v.platform_variant_options[2]?.key === key &&
          v.platform_variant_options[2]?.value
        ) {
          options.push(v.platform_variant_options[2]?.value);
        }
      }
    }
  }

  return [...new Set(options)];
};

/**
 * Get the variant that most closely matches *all* choices.
 * Variant *must* match the indexed choice (that is, the choice made by the user)
 * In most cases, this will find a perfect match, but in the event that there
 * isn't one, it will fall back to a lesser match.
 */
const getNewVariantId = (
  product: Product,
  index: number,
  choices: (string | undefined | null)[]
) => {
  // Get all variants that match the indexed choice
  const variantMatches = product.platform_variants
    .filter(
      (variant) =>
        variant.platform_variant_options[index].value === choices[index]
    )
    // Calculate matchiness
    .map((variant) => {
      const options = variant.platform_variant_options;
      return {
        variant,
        matchCount:
          (options[0].value === choices[0] ? 1 : 0) +
          (options[1]?.value === choices[1] ? 1 : 0) +
          (options[2]?.value === choices[2] ? 1 : 0),
      };
    })
    // Sort by matchiness
    .sort((a, b) => {
      if (a.matchCount === b.matchCount) {
        return 0;
      } else {
        return a.matchCount < b.matchCount ? -1 : 1;
      }
    });

  return variantMatches.pop()?.variant;
};

const ProductSwapProductForm: React.FunctionComponent<
  ProductSwapProductFormProps
> = ({ selected, product, onChange }) => {
  const [collapsed, setCollapsed] = useState<boolean>(!selected);
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(
    product.platform_variants[0]?.platform_id || null
  );

  const ref = useRef<HTMLButtonElement>(null);
  const executeScroll = () => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth", inline: "end" });
    }, 0);
  };

  const variantKeys = useMemo(() => {
    const keys: string[] = [];
    for (const v of product.platform_variants) {
      for (const o of v.platform_variant_options) {
        // Hack for Shopify-based ecom platforms. Revisit if we add others.
        if (o.key !== "Title") {
          keys.push(o.key);
        }
      }
    }

    return [...new Set(keys)];
  }, [product]);

  const productHasVariants = useMemo(() => {
    if (variantKeys.length === 0) {
      return false;
    }

    if (variantKeys.length === 1 && variantKeys[0] === "Title") {
      return false;
    }

    return true;
  }, [variantKeys]);

  const selectedVariant = product.platform_variants.find(
    (v) => v.platform_id === selectedVariantId
  );

  const choice0 = selectedVariant?.platform_variant_options[0].value;
  const choice1 = selectedVariant?.platform_variant_options[1]?.value || "";
  const choice2 = selectedVariant?.platform_variant_options[2]?.value || "";

  const variantKeysText = useMemo(
    () =>
      `Select your ${new Intl.ListFormat("en-US", {
        style: "long",
        type: "conjunction",
      }).format(variantKeys)}`,
    [variantKeys]
  );

  const variantValuesText = [choice0, choice1, choice2]
    .filter((v) => !!v)
    .join(", ");

  const variantOptions = [
    getVariantOptions(product, variantKeys[0], 0),
    getVariantOptions(product, variantKeys[1], 1, choice0),
    getVariantOptions(product, variantKeys[2], 2, choice0, choice1),
  ];

  const handleUpdate = useCallback(() => {
    onChange(product.platform_id, selectedVariantId);
  }, [onChange, product.platform_id, selectedVariantId]);

  useEffect(() => {
    if (!selected) {
      setCollapsed(true);
    } else {
      handleUpdate();
    }
  }, [handleUpdate, selected]);

  const getPriceForDisplay = (price: any) => {
    if (
      product.platform_plan_ecommerce_products.length < 0 ||
      product.platform_plan_ecommerce_products[0]?.discount_type !==
        "percentage" ||
      Number(product.platform_plan_ecommerce_products[0]?.discount_amount) === 0
    ) {
      return <>{`$${Number(price).toFixed(2)} USD`}</>;
    }

    const p = Number(price);
    const d = Number(
      product.platform_plan_ecommerce_products[0]?.discount_amount || "0"
    );

    const newPrice = Number(p - (p * d) / 100).toFixed(2);

    return (
      <>
        <span tw="line-through">{`$${Number(price).toFixed(2)}`}</span>
        <span>{`$${newPrice} USD`}</span>
      </>
    );
  };

  return (
    <button
      key={product.platform_id}
      ref={ref}
      className={classNames({
        "flow-product-swap__product": true,
        "flow-product-swap__product--selected": selected,
        "flow-product-swap__product--expanded": !collapsed,
        "flow-product-swap__product--none": !productHasVariants,
        "flow-product-swap__product--sm":
          variantKeys.length === 1 && productHasVariants,
        "flow-product-swap__product--md":
          variantKeys.length === 2 && productHasVariants,
      })}
      onClick={() => {
        executeScroll();
        if (collapsed) {
          setCollapsed(false);
        }
        if (!selected) {
          handleUpdate();
        }
      }}
    >
      <div
        className="flow-product-swap__product__header"
        onClick={() => !collapsed && setCollapsed(true)}
      >
        <div
          className={classNames({
            "flow-product-swap__product__header__img": true,
            "flow-product-swap__product__header__img--selected": selected,
          })}
        >
          {product.image_url ? (
            <img
              src={product.image_url || undefined}
              alt=""
              tw="max-h-full max-w-full"
            />
          ) : (
            <MissingImagePlaceHolder color={selected ? "blue" : "gray"} />
          )}
        </div>
        <div className="flow-product-swap__product__header__wrapper">
          <div className="flow-product-swap__product__header__title">
            {product.name}
          </div>

          {!!selectedVariantId && productHasVariants && collapsed && (
            <div
              className={classNames({
                "flow-product-swap__product__header__subtitle": true,
                "flow-product-swap__product__header__subtitle--selected":
                  selected,
                "flow-product-swap__product__header__subtitle--valid":
                  !!selectedVariantId,
              })}
            >
              <span>{variantValuesText}</span>
            </div>
          )}
          {!!selectedVariantId &&
            !!selectedVariant?.price &&
            (collapsed || !productHasVariants) && (
              <div
                className={classNames({
                  "flow-product-swap__product__header__subtitle": true,
                  "flow-product-swap__product__header__subtitle--selected":
                    selected,
                  "flow-product-swap__product__header__subtitle--expanded":
                    !collapsed && productHasVariants,
                  "flow-product-swap__product__header__subtitle--valid":
                    !!selectedVariantId,
                })}
              >
                <span className="flow-product-swap__product__body__price">
                  {getPriceForDisplay(selectedVariant?.price)}
                </span>
              </div>
            )}
          {!selectedVariantId && (
            <div
              className={classNames({
                "flow-product-swap__product__header__subtitle": true,
                "flow-product-swap__product__header__subtitle--selected":
                  selected,
                "flow-product-swap__product__header__subtitle--expanded":
                  !collapsed && productHasVariants,
                "flow-product-swap__product__header__subtitle--valid":
                  !!selectedVariantId,
              })}
            >
              {variantKeysText}
            </div>
          )}
        </div>
      </div>
      {productHasVariants && (
        <div
          className={classNames({
            "flow-product-swap__product__body": true,
            "flow-product-swap__product__body--none": collapsed,
          })}
        >
          {variantKeys.map(
            (key, index) =>
              index <= 2 &&
              index >= 0 && (
                <div className="flow-product-swap__product__body__variant">
                  <div className="flow-product-swap__product__body__variant__title">
                    {key}
                  </div>
                  <select
                    className="flow-product-swap__product__body__variant__select"
                    value={
                      index === 0 ? choice0 : index === 1 ? choice1 : choice2
                    }
                    onChange={(val) => {
                      let newVariant;
                      switch (index) {
                        case 0:
                          newVariant = getNewVariantId(product, index, [
                            val.currentTarget.value,
                            choice1,
                            choice2,
                          ]);
                          break;

                        case 1:
                          newVariant = getNewVariantId(product, index, [
                            choice0,
                            val.currentTarget.value,
                            choice2,
                          ]);
                          break;

                        case 2:
                          newVariant = getNewVariantId(product, index, [
                            choice0,
                            choice1,
                            val.currentTarget.value,
                          ]);
                          break;
                      }

                      if (newVariant?.platform_id) {
                        setSelectedVariantId(newVariant?.platform_id);
                        onChange(product.platform_id, newVariant?.platform_id);
                      }
                    }}
                  >
                    {variantOptions[index].map((o) => (
                      <option key={`${product.platform_id}-${o}`} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
              )
          )}
          <div className="flow-product-swap__product__body__price">
            {selectedVariant ? (
              getPriceForDisplay(selectedVariant?.price)
            ) : (
              <>
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span className="flow-product-swap__product__body__price__help">
                  Make selections above to see price
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </button>
  );
};

export default ProductSwapProductForm;
