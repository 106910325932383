import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import tw from "twin.macro";

import {
  ChangePlanOfferFormQuery,
  offer_goal_enum,
  offer_type_enum,
  platform_enum,
} from "../../../../__generated__/graphql";
import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import PillRadio from "../../../../common/PillRadio";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../../common/platform/features";
import OfferIdDropdown from "../../../segments/OfferIdDropdown";
import PlanIdDropdown from "../../../segments/PlanIdDropdown";
import { ChangePlanOfferFormValues } from "../types";

interface ChangePlanDetailsFormPartialProps {
  mode: FormMode;
  goal?: offer_goal_enum;
  platform: platform_enum;
  isSubmitting: boolean;
}

const ChangePlanDetailsFormPartial: React.FunctionComponent<
  ChangePlanDetailsFormPartialProps
> = ({ mode, isSubmitting, platform, goal = offer_goal_enum.retention }) => {
  const { register, setValue, watch, control, formState } =
    useTranslatableFormContext<ChangePlanOfferFormValues>();

  const { data, loading } = useQuery<ChangePlanOfferFormQuery>(gql`
    query ChangePlanOfferFormQuery {
      platform_plan(limit: 5000) {
        platform_id
        name
        code
        platform_product {
          name
        }
        platform_connection {
          platform
        }
      }
    }
  `);

  const values = watch();

  useEffect(() => {
    const plan = data?.platform_plan.find(
      (plan) => plan.platform_id === values.planId
    );
    if (plan) {
      setValue("planName", plan.name);
    }
  }, [data, setValue, values.planId]);

  return (
    <>
      <FieldSet title="Plan details" disabled={isSubmitting || mode === "edit"}>
        <FieldRow>
          <FieldLabel>
            <label htmlFor="planId">Plan</label>
          </FieldLabel>
          <FieldInput>
            {platform !== "custom" ? (
              <>
                <Controller
                  control={control}
                  name="planId"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <PlanIdDropdown
                      value={field.value}
                      onChange={field.onChange}
                      width="260px"
                      fieldError={formState.errors.planId}
                    />
                  )}
                />
                {!platformHasFeature(
                  platform,
                  PlatformFeature.ChangePlanChangeAtEndOfPeriod
                ) && (
                  <FieldHint>
                    Subscriber will be switched to this plan immediately upon
                    accepting the offer.
                  </FieldHint>
                )}
              </>
            ) : (
              <>
                <TextInput
                  {...register("planId", { required: true })}
                  id="planId"
                  disabled={loading}
                  width="full"
                />
                <FieldHint>
                  Plan ID from your billing system to switch the customer to.
                </FieldHint>
              </>
            )}
          </FieldInput>
        </FieldRow>

        {platformHasFeature(
          platform,
          PlatformFeature.ChangePlanChangeAtEndOfPeriod
        ) && (
          <FieldRow>
            <FieldLabel>
              <label>Change at</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="changeAt"
                render={({ field }) => (
                  <PillRadio
                    value={field.value}
                    options={[
                      {
                        label: "Immediately",
                        value: "immediately",
                        tooltip: "Change to the specified plan immediately",
                      },
                      {
                        label: "End of period",
                        value: "period_end",
                        tooltip:
                          "Change to the specified plan at the end of the billing period",
                      },
                    ]}
                    onChange={field.onChange}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
        )}

        <fieldset
          css={values.changeAt !== "immediately" ? tw`hidden` : undefined}
        >
          <FieldRow
            css={
              !platformHasFeature(
                platform,
                PlatformFeature.ChangePlanResetBillingDate
              )
                ? tw`hidden`
                : undefined
            }
          >
            <FieldLabel>
              <label>Billing date</label>
            </FieldLabel>
            <FieldCheckboxInput>
              <input
                {...register("resetBillingDate")}
                id="resetBillingDate"
                type="checkbox"
                disabled={values.changeAt === "period_end"}
                checked={
                  values.changeAt === "period_end"
                    ? false
                    : values.resetBillingDate
                }
              />
              <label htmlFor="resetBillingDate">Reset billing date</label>
            </FieldCheckboxInput>
          </FieldRow>
          {platformHasFeature(
            platform,
            PlatformFeature.ChangePlanProration
          ) && (
            <FieldRow>
              <FieldLabel>
                <label>Proration</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("prorate")}
                  id="prorate"
                  type="checkbox"
                  disabled={values.changeAt === "period_end"}
                  checked={
                    values.changeAt === "period_end" ? false : values.prorate
                  }
                />
                <label htmlFor="prorate">
                  Apply prorated charges or credits
                </label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
        </fieldset>
      </FieldSet>

      {platformHasFeature(platform, PlatformFeature.ChangePlanApplyCoupon) && (
        <FieldSet title="Additional coupon offer" disabled={mode === "edit"}>
          <FieldRow>
            <FieldLabel>
              <label>Coupon offer</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="couponId"
                render={({ field }) => (
                  <OfferIdDropdown
                    value={field.value || ""}
                    onChange={field.onChange}
                    filterType={offer_type_enum.coupon}
                    resettable={true}
                    useId={true}
                    filterGoal={goal}
                  />
                )}
              />
              <FieldHint>
                Optionally apply a coupon offer to the new plan.
              </FieldHint>
            </FieldInput>
          </FieldRow>
        </FieldSet>
      )}
    </>
  );
};

export default ChangePlanDetailsFormPartial;
