import { useEffect, useRef } from "react";

import { language_enum } from "../../../__generated__/graphql";
import { isoToEnum } from "../../../common/languages";
import TranslationsProvider from "../../../common/translations/TranslationsProvider";
import useQueryParams from "../../../common/useQueryParams";
import ConfirmationContent from "./ConfirmationContent";

const Confirmation: React.FunctionComponent = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const queryParams = useQueryParams();

  const offerToken = queryParams.get("offer");
  if (!offerToken) {
    throw new Error("No offer token");
  }

  const isoLanguage = queryParams.get("language");

  const defaultLanguage = language_enum.en_us;

  const language =
    (isoLanguage ? isoToEnum(isoLanguage) : defaultLanguage) || defaultLanguage;
  const enabledLanguages = [defaultLanguage];

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];

      window.parent.postMessage(
        {
          source: "confirmation",
          message: "resize",
          payload: {
            height:
              entry.target.clientWidth > 500
                ? entry.target.getBoundingClientRect().height
                : null,
          },
        },
        "*"
      );
    });

    observer.observe(document.getElementById("root")!);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleReady = () => {
    window.parent.postMessage(
      {
        source: "confirmation",
        message: "ready",
      },
      "*"
    );
  };

  const handleConfirm = () => {
    window.parent.postMessage(
      {
        source: "confirmation",
        message: "confirmed",
      },
      "*"
    );
  };

  const handleCancel = () => {
    window.parent.postMessage(
      {
        source: "confirmation",
        message: "canceled",
      },
      "*"
    );
  };

  return (
    <TranslationsProvider
      language={language}
      defaultLanguage={defaultLanguage}
      enabledLanguages={enabledLanguages}
    >
      <div ref={containerRef}>
        <ConfirmationContent
          offerToken={offerToken}
          onReady={handleReady}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      </div>
    </TranslationsProvider>
  );
};

export default Confirmation;
