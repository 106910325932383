import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

import {
  CampaignAcceptOfferMutation,
  CampaignAcceptOfferMutationVariables,
  CampaignDiscoverOfferMutation,
  CampaignDiscoverOfferMutationVariables,
  CampaignEndViewMutation,
  CampaignEndViewMutationVariables,
  CampaignOfferFragment,
  CampaignStartViewMutation,
  CampaignStartViewMutationVariables,
  language_enum,
  subscriber_campaign_view_status_enum,
} from "../../../__generated__/graphql";
import getCampaignObjectVersion from "../../../common/campaign/getCampaignObjectVersion";
import useSubscriberCampaignByToken from "../../../common/campaign/useSubscriberCampaignByToken";
import TheCampaignOfferFragment from "../../../common/fragments/CampaignOfferFragment";
import { isoToEnum } from "../../../common/languages";
import TranslationsProvider from "../../../common/translations/TranslationsProvider";
import useQueryParams from "../../../common/useQueryParams";
import { PropertyValuesProvider } from "../../properties/lib/propertyValues";
import { PropertyValues } from "../../properties/lib/types";
import CampaignContent from "./CampaignContent";
import CampaignVersionProvider from "./CampaignVersionProvider";
import mapCampaignText from "./lib/mapCampaignText";

const MESSAGE_ACCEPT_OFFER = "acceptOffer";

const Campaign: React.FunctionComponent = () => {
  const { token } = useParams<{ token: string }>();

  const queryParams = useQueryParams();
  const isoLanguage = queryParams.get("language");

  const [isLoading, setIsLoading] = useState(true);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [offer, setOffer] = useState<CampaignOfferFragment | null>(null);

  const [viewToken, setViewToken] = useState<string | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    data: subscriberCampaignData,
    loading: subscriberCampaignDataLoading,
  } = useSubscriberCampaignByToken(token || "");

  const campaignVersion = "published";

  const [discoverOffer, discoverOfferResult] = useMutation<
    CampaignDiscoverOfferMutation,
    CampaignDiscoverOfferMutationVariables
  >(
    gql`
      mutation CampaignDiscoverOfferMutation(
        $input: SubscriberCampaignOfferInput!
      ) {
        subscriberCampaignOffer(input: $input) {
          offer {
            ...CampaignOfferFragment
          }
        }
      }
      ${TheCampaignOfferFragment}
    `
  );
  const [startView, startViewResult] = useMutation<
    CampaignStartViewMutation,
    CampaignStartViewMutationVariables
  >(
    gql`
      mutation CampaignStartViewMutation(
        $input: StartSubscriberCampaignViewInput!
      ) {
        startSubscriberCampaignView(input: $input) {
          token
        }
      }
    `
  );
  const [endView, endViewResult] = useMutation<
    CampaignEndViewMutation,
    CampaignEndViewMutationVariables
  >(
    gql`
      mutation CampaignEndViewMutation(
        $input: CompleteSubscriberCampaignViewInput!
      ) {
        completeSubscriberCampaignView(input: $input) {
          success
        }
      }
    `
  );
  const [acceptOffer] = useMutation<
    CampaignAcceptOfferMutation,
    CampaignAcceptOfferMutationVariables
  >(gql`
    mutation CampaignAcceptOfferMutation(
      $subscriberCampaignToken: String!
      $offerId: Int!
    ) {
      acceptCampaignOffer(
        input: {
          subscriberCampaignToken: $subscriberCampaignToken
          offerId: $offerId
        }
      ) {
        success
        needsPaymentMethod
      }
    }
  `);

  const subscriberCampaign =
    subscriberCampaignData?.subscriber_campaign[0] || undefined;
  const campaign = subscriberCampaign?.campaign || undefined;

  useEffect(() => {
    if (discoverOfferResult.called || !subscriberCampaign?.token) {
      return;
    }

    discoverOffer({
      variables: {
        input: {
          subscriberCampaignToken: subscriberCampaign.token,
        },
      },
    });
  }, [discoverOfferResult.called, subscriberCampaign, discoverOffer]);

  useEffect(() => {
    if (
      !subscriberCampaign?.token ||
      endViewResult.called ||
      !discoverOfferResult.data?.subscriberCampaignOffer
    ) {
      return;
    }

    const result = discoverOfferResult.data.subscriberCampaignOffer;

    if (!result.offer) {
      return;
    }

    startView({
      variables: {
        input: {
          offerId: result.offer?.id,
          subscriberCampaignToken: subscriberCampaign.token,
        },
      },
    });

    setOffer(result.offer || null);
  }, [
    subscriberCampaign,
    discoverOfferResult.data,
    endViewResult.called,
    startView,
  ]);

  useEffect(() => {
    if (!startViewResult.data?.startSubscriberCampaignView?.token) {
      return;
    }

    setViewToken(startViewResult.data?.startSubscriberCampaignView?.token);
  }, [startViewResult.data]);

  useEffect(() => {
    setIsLoading(
      subscriberCampaignDataLoading ||
        discoverOfferResult.loading ||
        startViewResult.loading
    );
  }, [
    subscriberCampaignDataLoading,
    discoverOfferResult.loading,
    startViewResult.loading,
  ]);

  useEffect(() => {
    if (finishedLoading || isLoading) {
      return;
    }

    window.parent.postMessage(
      {
        source: "campaign",
        message: "ready",
      },
      "*"
    );

    setFinishedLoading(true);
  }, [isLoading, finishedLoading]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];

      window.parent.postMessage(
        {
          source: "campaign",
          message: "resize",
          payload: {
            height:
              entry.target.clientWidth > 500 ? entry.target.scrollHeight : null,
          },
        },
        "*"
      );
    });

    observer.observe(document.getElementById("root")!);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleDismissOffer = async () => {
    completeView(subscriber_campaign_view_status_enum.dismissed);
  };

  const defaultLanguage = campaign?.default_language || language_enum.en_us;

  const language =
    (isoLanguage ? isoToEnum(isoLanguage) : defaultLanguage) || defaultLanguage;
  const enabledLanguages = [defaultLanguage];

  const version =
    campaign && getCampaignObjectVersion(campaign, campaignVersion);

  const propertyValues: PropertyValues = {};

  const subscriberProperties =
    subscriberCampaign?.subscriber.subscriber_properties || [];
  const subscriptionProperties =
    subscriberCampaign?.subscription?.subscription_properties || [];

  for (const property of [...subscriberProperties, ...subscriptionProperties]) {
    propertyValues[property.property_id.toString()] = property.value;
  }

  const propertyConfig = (campaign?.account.properties || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: {
        name: current.name,
        type: current.type,
        numberFormat: current.format,
      },
    }),
    {}
  );

  if (subscriberCampaign?.subscriber.name) {
    const name = subscriberCampaign.subscriber.name;
    const split = name.split(" ");

    if (split.length > 0) {
      propertyValues["first_name"] = split[0];
    }

    if (split.length > 1) {
      propertyValues["last_name"] = split[split.length - 1];
    }
  }

  if (subscriberCampaign?.subscriber.email) {
    propertyValues["email"] = subscriberCampaign.subscriber.email;
  }

  const completeView = useCallback(
    async (status: subscriber_campaign_view_status_enum) => {
      if (!viewToken) {
        throw new Error("No view token");
      }

      await endView({
        variables: {
          input: {
            token: viewToken,
            status,
          },
        },
      });

      window.parent.postMessage(
        {
          source: "campaign",
          message: "complete",
          payload: {
            redirectUrl: version?.redirect_url,
          },
        },
        "*"
      );
    },
    [endView, version, viewToken]
  );

  const handleAcceptOffer = useCallback(
    async (confirmed: boolean) => {
      const acceptedOffer = offer;
      if (!acceptedOffer) {
        throw new Error("No offer");
      }

      if (!version) {
        throw new Error("No campaign version");
      }

      if (!token) {
        throw new Error("No subscriber campaign token");
      }

      if (!confirmed && acceptedOffer.confirmation_enabled) {
        window.parent.postMessage(
          {
            source: "campaign",
            message: "showConfirmation",
          },
          "*"
        );
        return;
      }

      const result = await acceptOffer({
        variables: {
          subscriberCampaignToken: token,
          offerId: acceptedOffer.id,
        },
      });

      if (
        version.collect_payment &&
        result.data?.acceptCampaignOffer.needsPaymentMethod
      ) {
        window.parent.postMessage(
          {
            source: "campaign",
            message: "collectPayment",
          },
          "*"
        );
        return;
      }

      completeView(subscriber_campaign_view_status_enum.accepted);
    },
    [acceptOffer, completeView, offer, token, version]
  );

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      switch (e.data.message) {
        case MESSAGE_ACCEPT_OFFER:
          handleAcceptOffer(true);
          break;
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [handleAcceptOffer]);

  const handleDeclineOffer = () => {
    completeView(subscriber_campaign_view_status_enum.declined);
  };

  const firstSeen = subscriberCampaign?.subscriber_campaign_offers.find(
    (o) => o.offer.id === offer?.id
  )?.first_view.aggregate?.min?.created_at;

  const containerProps = {
    offer,
    onAcceptOffer: () => handleAcceptOffer(false),
    onDeclineOffer: handleDeclineOffer,
    onDismissOffer: handleDismissOffer,
    cssValue: version?.css || "",
    campaignText: mapCampaignText(
      campaign?.campaign_texts || [],
      campaignVersion
    ),
    startTimerFrom: firstSeen ? new Date(firstSeen) : null,
  };

  return (
    <CampaignVersionProvider version={campaignVersion}>
      <TranslationsProvider
        language={language}
        defaultLanguage={defaultLanguage}
        enabledLanguages={enabledLanguages}
      >
        <PropertyValuesProvider
          propertyValues={propertyValues}
          propertyConfig={propertyConfig}
        >
          <div ref={containerRef}>
            <CampaignContent {...containerProps} />
          </div>
        </PropertyValuesProvider>
      </TranslationsProvider>
    </CampaignVersionProvider>
  );
};
export default Campaign;
