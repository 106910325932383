import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Controller } from "react-hook-form";
import tw from "twin.macro";

import {
  language_enum,
  offer_autopilot_strategy_enum,
  offer_goal_enum,
} from "../../../../__generated__/graphql";
import isSlateContentEmpty from "../../../../common/editor/lib/isContentEmpty";
import EditorSelector from "../../../../common/editor2/EditorSelector";
import { isContentEmpty } from "../../../../common/editor2/lib";
import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldError from "../../../../common/form/FieldError";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldRowBlock from "../../../../common/form/FieldRowBlock";
import FieldSetTitle from "../../../../common/form/FieldSetTitle";
import FormBanner from "../../../../common/form/FormBanner";
import ImageInput from "../../../../common/form/input/ImageInput";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import useAccountFeatures from "../../../../common/useAccountFeatures";
import LanguageRadio from "../../../flow/edit/LanguageRadio";
import usePaidFeature, {
  generatePaidFeatureClassName,
} from "../../../upgrade-account/usePaidFeature";
import useValidateHeadline from "../../lib/useValidateHeadline";
import { CouponOfferFormValues } from "../types";

interface OfferContentFormPartialProps {
  isSubmitting: boolean;
  goal?: offer_goal_enum;
  strategy?: offer_autopilot_strategy_enum;
  onChangeEditingLanguage: (value: language_enum) => void;
  hideButtonText?: boolean;
}

const OfferContentFormPartial: React.FunctionComponent<
  OfferContentFormPartialProps
> = ({
  isSubmitting,
  goal = offer_goal_enum.retention,
  strategy,
  onChangeEditingLanguage,
  hideButtonText,
}) => {
  const { isFreeMode, setPaidFeatureRef } = usePaidFeature();

  const {
    register,
    watch,
    formState,
    control,
    editingLanguage,
    defaultLanguage,
    enabledLanguages,
    setEditingLanguage,
    forms,
    trigger,
  } = useTranslatableFormContext<CouponOfferFormValues>();

  const values = watch();
  const validateHeadline = useValidateHeadline(values.image);

  const { features } = useAccountFeatures();

  const handleChangeEditingLanguage = (value: language_enum) => {
    setEditingLanguage(value);
    onChangeEditingLanguage(value);
  };

  return ["copy", "discount_copy"].includes(String(strategy)) ? (
    <fieldset disabled={isSubmitting}>
      <FieldSetTitle>Offer {values.style}</FieldSetTitle>

      <FormBanner icon={faInfoCircle} color="light-gray">
        Autopilot writes copy for the variants. View and approve this copy on
        the detail screen after the offer has been created.
      </FormBanner>
    </fieldset>
  ) : (
    <>
      <fieldset disabled={isSubmitting} ref={(ref) => setPaidFeatureRef(ref)}>
        <FieldSetTitle>Offer {values.style}</FieldSetTitle>
        <FieldRow
          css={values.style !== "modal" ? tw`hidden` : undefined}
          className={generatePaidFeatureClassName(
            'Upgrade your plan to add a "Special Offer" banner to offers.',
            isFreeMode,
            "form"
          )}
        >
          <FieldLabel>
            <label>Banner</label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("showBanner")}
              id="showBanner"
              type="checkbox"
              disabled={isFreeMode}
            />
            <label htmlFor="showBanner">Show "Special Offer" banner</label>
          </FieldCheckboxInput>
        </FieldRow>
        {goal === offer_goal_enum.conversion && !!values.timerMode && (
          <FieldRow>
            <FieldLabel>
              <label>Countdown timer</label>
            </FieldLabel>
            <FieldCheckboxInput>
              <input
                {...register("countdownTimer")}
                id="countdownTimer"
                type="checkbox"
              />
              <label htmlFor="countdownTimer">Show countdown timer</label>
            </FieldCheckboxInput>
          </FieldRow>
        )}
        {(enabledLanguages.length > 1 || isFreeMode) && (
          <div
            className={generatePaidFeatureClassName(
              "Upgrade your plan to add support for multiple languages.",
              isFreeMode,
              "form"
            )}
          >
            <LanguageRadio
              value={editingLanguage}
              languages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              onChange={handleChangeEditingLanguage}
            />
          </div>
        )}
        <FieldRow>
          <FieldLabel>
            <label htmlFor="headline">Headline</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("headline", { validate: validateHeadline })}
              id="headline"
              width="full"
              fieldError={formState.errors.headline}
            />
            <FieldError error={formState.errors.headline} />
          </FieldInput>
        </FieldRow>

        {goal === "retention" && (
          <FieldRow
            className={generatePaidFeatureClassName(
              "Upgrade your plan to add an image to your offer.",
              isFreeMode,
              "form"
            )}
          >
            <FieldLabel>
              <label>Image</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <ImageInput
                    value={field.value}
                    onChange={(logoUrl) => {
                      field.onChange(logoUrl);
                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
        )}
        <FieldRowBlock>
          <FieldLabel>
            <label>Content</label>
          </FieldLabel>
          <Controller
            control={control}
            name="content"
            rules={{
              validate: (val) =>
                !!values.image
                  ? true
                  : values.contentFormat === "slate"
                  ? !isSlateContentEmpty(JSON.parse(val))
                  : !isContentEmpty(JSON.parse(val)),
            }}
            render={({ field }) => {
              const form = forms[editingLanguage];
              if (!form) {
                return <></>;
              }

              return (
                <>
                  <EditorSelector
                    initialValueKey={editingLanguage}
                    isOfferContent={true}
                    hasCustomProperties={!!features.custom_properties}
                    format={form.contentFormat}
                    value={form.content}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    fieldError={formState.errors.content}
                    imagesEnabled={goal === "conversion" ? false : undefined}
                    videosEnabled={goal === "conversion" ? false : undefined}
                  />
                  <FieldError error={formState.errors.content} />
                </>
              );
            }}
          />
        </FieldRowBlock>
        {!hideButtonText && (
          <FieldRow
            className={generatePaidFeatureClassName(
              "Upgrade your plan to modify offer button text.",
              isFreeMode,
              "form"
            )}
          >
            <FieldLabel>
              <label>Button text</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("buttonText", { required: true })}
                id="buttonText"
                width="md"
                fieldError={formState.errors.buttonText}
                disabled={isFreeMode}
              />
            </FieldInput>
          </FieldRow>
        )}
      </fieldset>
    </>
  );
};

export default OfferContentFormPartial;
