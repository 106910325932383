import { gql, useQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";

import {
  AppOfferFragment,
  language_enum,
  offer_autopilot_strategy_enum,
  offer_autopilot_success_metric_enum,
  offer_goal_enum,
  offer_type_enum,
  OfferDetailsFragment,
  OfferFormContainerCouponsQuery,
  OfferPlatformEcommerceProductsQuery,
  platform_enum,
} from "../../../__generated__/graphql";
import FormMode from "../../../common/form/FormMode";
import useTranslatableForm, {
  TranslatedForms,
} from "../../../common/form/useTranslatableForm";
import { TranslatableFormProvider } from "../../../common/form/useTranslatableFormContext";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import getDefaultFormValues from "./lib/getDefaultFormValues";
import getInitialFormValues from "./lib/getInitialFormValues";
import getTranslatableFields from "./lib/getTranslatableFields";
import useSubmitOffer from "./lib/useSubmitOffer";
import renderOfferForm from "./renderOfferForm";
import { OfferFormValues } from "./types";

export interface OfferFormContainerProps {
  mode: FormMode;
  platform: platform_enum;
  offer?: OfferDetailsFragment;
  offerType: offer_type_enum;
  initialValues?: TranslatedForms<OfferFormValues>;
  tags: string[];
  goal?: offer_goal_enum;
  strategy?: offer_autopilot_strategy_enum;
  successMetric?: offer_autopilot_success_metric_enum;
  editingLanguage: language_enum;
  isFreeMode: boolean;
  onSubmit?: (values: TranslatedForms<OfferFormValues>) => void;
  onChange?: (values: TranslatedForms<OfferFormValues>) => void;
  onClose: (offer: AppOfferFragment | null) => void;
  onChangeEditingLanguage: (language: language_enum) => void;
  ecommerceProducts?: OfferPlatformEcommerceProductsQuery["platform_ecommerce_product"];
  showBannerOverride?: boolean | undefined;
}

const OfferFormContainer: React.FunctionComponent<OfferFormContainerProps> = ({
  mode,
  platform,
  offerType,
  offer,
  initialValues,
  tags,
  goal = offer_goal_enum.retention,
  strategy,
  successMetric,
  editingLanguage,
  isFreeMode,
  onSubmit = () => {},
  onChange = () => {},
  onClose,
  onChangeEditingLanguage,
  ecommerceProducts,
  showBannerOverride = undefined,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();

  const defaultValues = useMemo(() => {
    if (initialValues) {
      return initialValues;
    }

    if (offer) {
      return getInitialFormValues(offer, enabledLanguages);
    }

    return getDefaultFormValues({
      type: offerType,
      enabledLanguages,
      platform,
      products: ecommerceProducts,
      showBannerOverride,
      goal,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, offer]);

  const [formValues, setFormValues] =
    useState<TranslatedForms<OfferFormValues>>(defaultValues);

  const form = useTranslatableForm({
    defaultValues: defaultValues,
    initialLanguage: editingLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields(offerType, goal),
  });

  const { handleSubmitOffer, isSubmitting } = useSubmitOffer({
    mode,
    offer,
    goal,
    defaultLanguage,
    onClose,
    strategy,
    successMetric,
  });

  const { data: couponData } = useQuery<OfferFormContainerCouponsQuery>(
    gql`
      query OfferFormContainerCouponsQuery {
        platform_coupon(
          where: {
            _or: [
              { duration: { _in: [once, repeating, forever] } }
              {
                duration_interval: { _eq: month }
                duration_interval_count: { _eq: 1 }
              }
            ]
            apply_to: { _neq: shipping }
          }
          limit: 5000
        ) {
          platform_id
          name
          code
          type
          amount_off
          duration
          duration_interval
          duration_count
        }
      }
    `,
    { skip: offerType !== "coupon" || mode === "edit" }
  );

  const handleFormChange = (forms: TranslatedForms<OfferFormValues>) => {
    if (JSON.stringify(forms) !== JSON.stringify(formValues)) {
      setFormValues(cloneDeep(forms));
    }
  };

  useEffect(() => {
    if (offer && enabledLanguages) {
      setFormValues(getInitialFormValues(offer, enabledLanguages));
    }
  }, [offer, enabledLanguages]);

  useEffect(() => {
    onChange(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    if (isSubmitting && onSubmit) {
      onSubmit(formValues);
    }
  }, [formValues, isSubmitting, onSubmit]);

  return (
    <TranslatableFormProvider {...form}>
      {renderOfferForm({
        formValues,
        mode,
        offerType,
        goal,
        platform,
        tags,
        coupons: couponData?.platform_coupon,
        ecommerceProducts,
        editingLanguage,
        onChange: handleFormChange,
        onSubmit: (values) => {
          handleFormChange(values);
          handleSubmitOffer(values);
        },
        onChangeEditingLanguage,
        isSubmitting,
        isFreeMode,
        strategy,
        successMetric,
      })}
    </TranslatableFormProvider>
  );
};

export default OfferFormContainer;
