import classNames from "classnames";
import tw from "twin.macro";

import {
  FlowOfferFragment,
  language_enum,
  offer_style_enum,
} from "../../../__generated__/graphql";
import translationText from "../../../common/translationText";
import { usePropertyValues } from "../../properties/lib/propertyValues";
import renderOfferContent from "./lib/renderOfferContent";

interface OfferContentProps {
  offer: FlowOfferFragment | null;
  previewMode: boolean;
  language: language_enum;
  defaultLanguage: language_enum;
  baseClassName: string;
  disableVideoAutoPlay?: boolean;
  onImageLoad?: () => void;
  showImage?: boolean;
}

const Placeholder = tw.span`text-type-light`;

const OfferContent: React.FunctionComponent<OfferContentProps> = ({
  offer,
  previewMode,
  language,
  defaultLanguage,
  baseClassName,
  disableVideoAutoPlay,
  onImageLoad,
  showImage = true,
}) => {
  const headline = translationText(
    offer?.headline_translation,
    language,
    defaultLanguage
  ) ? (
    translationText(offer?.headline_translation, language, defaultLanguage)
  ) : !offer?.image_url ? (
    <Placeholder>Headline</Placeholder>
  ) : null;

  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  const content = renderOfferContent({
    offer,
    previewMode,
    language,
    defaultLanguage,
    propertyValues,
    propertyConfig,
    showPropertyPlaceholders: showPlaceholders,
    disableVideoAutoPlay,
  });

  return offer ? (
    <div>
      <h3
        className={classNames({
          [`${baseClassName}__headline`]: true,
          [`${baseClassName}__headline--has-ribbon`]:
            offer.show_banner && offer.style !== offer_style_enum.banner,
          [`${baseClassName}__headline--banner`]:
            offer.style === offer_style_enum.banner,
        })}
      >
        {headline}
      </h3>

      {showImage &&
        !!offer.image_url &&
        (offer.style === offer_style_enum.modal ||
          offer.style === offer_style_enum.step) && (
          <img
            className={`${baseClassName}__image`}
            src={offer.image_url}
            alt=""
            onLoad={() => onImageLoad && onImageLoad()}
          />
        )}

      <div
        className={classNames({
          [`${baseClassName}__content`]: true,
          [`${baseClassName}__content--banner`]:
            offer.style === offer_style_enum.banner,
        })}
      >
        {content}
      </div>
    </div>
  ) : null;
};

export default OfferContent;
