import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { styled, theme } from "twin.macro";

import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FormMode from "../../../../common/form/FormMode";
import { ProductSwapOfferFormValues } from "../types";

interface ProductSwapVariantsFormPartialProps {
  mode: FormMode;
  variants: any; //@TODO figure out why I can't use type from form here
  initialValues: any;
  control: Control<ProductSwapOfferFormValues, any>;
  register: UseFormRegister<ProductSwapOfferFormValues>;
  setValue: UseFormSetValue<ProductSwapOfferFormValues>;
  watch: UseFormWatch<ProductSwapOfferFormValues>;
  productIndex: number;
}

const VariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
  flex: 1 0 0;
  width: 100%;
`;

const VariantsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  align-self: stretch;
`;

const VariantsTitle = styled.div`
  flex: 1 0 0;
  color: var(--Gray-2, ${theme`colors.gray.800`});
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const VariantsLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
`;

const VariantsLink = styled.a`
  color: var(--Blue-1, ${theme`colors.blue.DEFAULT`});
  cursor: pointer;
`;

const VariantRow = styled.div<{
  hasBorder: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0.325rem;
  align-self: stretch;
  padding-bottom: ${(props) => (props.hasBorder ? "0.325rem" : "0")};
  border-bottom: ${(props) =>
    props.hasBorder ? `0.0625rem solid ${theme`colors.divider`}` : "0"};
  width: 100%;
`;

const VariantLabel = styled.label`
  color: var(--Gray-1, #333);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

const ProductSwapVariantsFormPartial: React.FunctionComponent<
  ProductSwapVariantsFormPartialProps
> = ({
  mode,
  variants,
  initialValues,
  control,
  register,
  setValue,
  productIndex,
}) => {
  const [fieldsAdded, setFieldsAdded] = useState(false);
  const { fields, append } = useFieldArray({
    control,
    name: `products.${productIndex}.variants` as "products.0.variants",
  });

  useEffect(() => {
    if (fieldsAdded) {
      return;
    }
    if (mode === "edit" && !initialValues) {
      return;
    }
    setValue(`products.${productIndex}.variants`, []);
    for (const variant of variants || []) {
      append({
        variantId: variant.platform_id,
        name: variant.name,
        price: variant.price,
        value:
          mode === "create"
            ? true
            : initialValues.some(
                (v: any) => v.variantId === variant.platform_id
              ),
        options: variant.platform_variant_options.map((option: any) => ({
          key: option.key,
          value: option.value,
        })),
      });
    }
    setFieldsAdded(true);
  }, [
    append,
    fieldsAdded,
    initialValues,
    mode,
    productIndex,
    setValue,
    variants,
  ]);

  const selectAll = () => {
    for (const [index] of fields.entries()) {
      setValue(`products.${productIndex}.variants.${index}.value`, true);
    }
  };

  const deselectAll = () => {
    for (const [index] of fields.entries()) {
      setValue(`products.${productIndex}.variants.${index}.value`, false);
    }
  };

  return (
    <VariantsWrapper>
      <VariantsHeader>
        <VariantsTitle>Variants</VariantsTitle>
        {mode === "create" && (
          <VariantsLinks>
            <VariantsLink onClick={selectAll}>Select all</VariantsLink>
            <VariantsLink onClick={deselectAll}>Deselect all</VariantsLink>
          </VariantsLinks>
        )}
      </VariantsHeader>
      {fields.map((item, index) => (
        <FieldCheckboxInput key={item.id} tw="w-full">
          <Controller
            control={control}
            {...register(
              `products.${productIndex}.variants.${index}.value` as const
            )}
            render={({ field }) => (
              <VariantRow hasBorder={index !== fields.length - 1}>
                <input
                  id={field.name}
                  type="checkbox"
                  checked={field.value}
                  onChange={field.onChange}
                />
                <VariantLabel htmlFor={field.name}>{item.name}</VariantLabel>
              </VariantRow>
            )}
          />
        </FieldCheckboxInput>
      ))}
    </VariantsWrapper>
  );
};

export default ProductSwapVariantsFormPartial;
