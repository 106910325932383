import { useEffect, useState } from "react";

import {
  language_enum,
  offer_autopilot_strategy_enum,
  offer_autopilot_success_metric_enum,
  offer_goal_enum,
  offer_type_enum,
  OfferFormContainerCouponsQuery,
  platform_enum,
} from "../../../__generated__/graphql";
import FormMode from "../../../common/form/FormMode";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import useTranslatableFormContext from "../../../common/form/useTranslatableFormContext";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import { CouponOfferFormValues } from "./../forms/types";
import ConfirmationModalFormPartial from "./partials/ConfirmationModalFormPartial";
import CouponDetailsFormPartial from "./partials/CouponDetailsFormPartial";
import OfferBaseFormPartial from "./partials/OfferBaseFormPartial";
import OfferContentFormPartial from "./partials/OfferContentFormPartial";
import OfferMetadataFormPartial from "./partials/OfferMetadataFormPartial";

interface CouponOfferFormProps {
  mode: FormMode;
  goal?: offer_goal_enum;
  isSubmitting: boolean;
  coupons: OfferFormContainerCouponsQuery["platform_coupon"] | undefined;
  tags: string[];
  platform: platform_enum;
  isFreeMode: boolean;
  onChange: (values: TranslatedForms<CouponOfferFormValues>) => void;
  onSubmit: (values: TranslatedForms<CouponOfferFormValues>) => void;
  strategy?: offer_autopilot_strategy_enum;
  successMetric?: offer_autopilot_success_metric_enum;
  onChangeEditingLanguage: (value: language_enum) => void;
}

const CouponOfferForm: React.FunctionComponent<CouponOfferFormProps> = ({
  mode,
  onChange,
  onSubmit,
  isSubmitting,
  coupons,
  tags,
  platform,
  goal = offer_goal_enum.retention,
  strategy,
  successMetric,
  onChangeEditingLanguage,
}) => {
  const { register, handleSubmit, forms } =
    useTranslatableFormContext<CouponOfferFormValues>();

  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(mode === "create" ? formRef : null);

  useEffect(() => {
    onChange(forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  return (
    <form
      id="create-offer"
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input {...register("type")} type="hidden" value="coupon" />

      <OfferBaseFormPartial
        mode={mode}
        isSubmitting={isSubmitting}
        offerType={offer_type_enum.coupon}
        platform={platform}
        tags={tags}
        strategy={strategy}
        successMetric={successMetric}
        goal={goal}
      />

      <CouponDetailsFormPartial
        mode={mode}
        isSubmitting={isSubmitting}
        platform={platform}
        coupons={coupons}
        goal={goal}
      />

      <OfferContentFormPartial
        isSubmitting={isSubmitting}
        strategy={strategy}
        goal={goal}
        onChangeEditingLanguage={onChangeEditingLanguage}
      />

      {goal === "conversion" && (
        <ConfirmationModalFormPartial isSubmitting={isSubmitting} />
      )}

      <OfferMetadataFormPartial isSubmitting={isSubmitting} />
    </form>
  );
};

export default CouponOfferForm;
