import { Temporal } from "@js-temporal/polyfill";
import { Controller } from "react-hook-form";

import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import DateTimePickerInput from "../../../../common/form/input/DateTimePickerInput";
import SelectInput from "../../../../common/form/input/SelectInput";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import { OfferBaseFormValues } from "../types";

const ConversionOfferTimeModePartial: React.FunctionComponent = () => {
  const { register, watch, control } =
    useTranslatableFormContext<OfferBaseFormValues>();

  const watchTimerMode = watch("timerMode", "");

  return (
    <>
      <FieldRow>
        <FieldLabel>
          <label htmlFor="timeMode">Time mode</label>
        </FieldLabel>
        <FieldInput>
          <SelectInput
            {...register("timerMode")}
            id="style"
            defaultValue="modal"
          >
            <option value="">None</option>
            <option value="fixed">Fixed</option>
            <option value="from_first_seen">From first seen</option>
          </SelectInput>
        </FieldInput>
      </FieldRow>

      {watchTimerMode === "from_first_seen" && (
        <FieldRow>
          <FieldLabel>
            <label htmlFor="offerLength">Length of offer</label>
          </FieldLabel>
          <FieldInput>
            <SelectInput
              {...register("offerLength")}
              id="style"
              defaultValue="24:00:00"
            >
              <option value="01:00:00">1 hour</option>
              <option value="12:00:00">12 hours</option>
              <option value="24:00:00">24 hours</option>
            </SelectInput>
          </FieldInput>
        </FieldRow>
      )}

      {watchTimerMode === "fixed" && (
        <>
          <FieldRow tw="border-b-0">
            <FieldLabel>
              <label htmlFor="startTime">Start date/time</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="startTime"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DateTimePickerInput
                    value={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    render={({ onClick }) => (
                      <TextInput
                        value={
                          !!field.value
                            ? (
                                field.value as Temporal.PlainDateTime
                              ).toLocaleString()
                            : ""
                        }
                        onChange={() => {}}
                        onFocus={onClick}
                        fieldError={fieldState.error}
                      />
                    )}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label htmlFor="endTime">End date/time</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="endTime"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DateTimePickerInput
                    value={field.value}
                    minDate={Temporal.Now.plainDateISO()}
                    onChange={field.onChange}
                    render={({ onClick }) => (
                      <TextInput
                        value={
                          !!field.value
                            ? (
                                field.value as Temporal.PlainDateTime
                              ).toLocaleString()
                            : ""
                        }
                        onChange={() => {}}
                        onFocus={onClick}
                        fieldError={fieldState.error}
                      />
                    )}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
        </>
      )}
    </>
  );
};

export default ConversionOfferTimeModePartial;
