import { Temporal } from "@js-temporal/polyfill";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  offer_goal_enum,
  offer_style_enum,
  offer_type_enum,
  PauseReason,
} from "../../../__generated__/graphql";
import { FlowError, FlowText } from "./lib/types";
import OfferContainer from "./OfferContainer";
import FlowModal, { FlowModalProps } from "./ui/FlowModal";

export type OfferModalProps = FlowModalProps & {
  offer: FlowOfferFragment | null;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  canReschedule?: boolean;
  flowText: FlowText;
  onAccept: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onDecline: () => void;
  isFinalStep: boolean;
  previewMode?: boolean;
  onImageLoad?: () => void;
  showDeclineButton?: boolean;
  showCloseX?: boolean;
  portalContainer?: Element;
  showOverlay?: boolean;
  onDismiss?: () => void;
  startTimerFrom?: Date | null | undefined;
  swappableProducts?: FlowSubscriptionFragment | null;
  disableVideoAutoPlay?: boolean;
  error?: FlowError;
  inline?: boolean;
};

const OfferModal: React.FunctionComponent<OfferModalProps> = ({
  offer,
  nextOrderDate,
  pauseReasons,
  canReschedule = true,
  flowText,
  onAccept,
  onDecline,
  previewMode = false,
  isFinalStep,
  onImageLoad,
  showDeclineButton = true,
  showCloseX = false,
  portalContainer = document.body,
  showOverlay,
  onDismiss = () => {},
  startTimerFrom = null,
  swappableProducts = null,
  disableVideoAutoPlay,
  error,
  inline = false,
  ...props
}) => {
  const [offerButtonsKey, setOfferButtonsKey] = useState(nanoid());

  const [imageLoaded, setImageLoaded] = useState(!offer?.image_url);

  useEffect(() => {
    if (props.isOpen) {
      setOfferButtonsKey(nanoid());
    }
  }, [props.isOpen]);

  const containerClassnames = classNames(
    previewMode && "flow-modal__container--preview",
    offer?.goal === offer_goal_enum.conversion &&
      "flow-modal__container--convert",
    offer?.type === offer_type_enum.product_swap &&
      "flow-modal__container--product-swap",
    (!offer || !imageLoaded) && "flow-modal__container--loading"
  );

  const handleImageLoad = () => {
    setImageLoaded(true);
    onImageLoad && onImageLoad();
  };

  return (
    <FlowModal
      {...props}
      style={offer?.style || offer_style_enum.modal}
      addContainerClass={containerClassnames}
      portalContainer={portalContainer}
      goal={offer?.goal}
      showOverlay={showOverlay}
      inline={inline}
      isOpen={props.isOpen && !!offer}
    >
      <OfferContainer
        offer={offer}
        previewMode={previewMode}
        baseClassName="flow-modal"
        disableVideoAutoPlay={disableVideoAutoPlay}
        onImageLoad={handleImageLoad}
        pauseReasons={pauseReasons}
        flowText={flowText}
        onAccept={onAccept}
        onAcceptGroupOffer={() => {}}
        onDecline={onDecline}
        nextOrderDate={nextOrderDate}
        swappableProducts={swappableProducts}
        startTimerFrom={startTimerFrom}
        showDeclineButton={showDeclineButton}
        isFinalStep={isFinalStep}
        showCloseX={showCloseX}
        error={error}
        offerButtonsKey={offerButtonsKey}
      />
    </FlowModal>
  );
};

export default OfferModal;
