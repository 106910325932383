import { Temporal } from "@js-temporal/polyfill";
import tw, { styled } from "twin.macro";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  PauseReason,
} from "../../../__generated__/graphql";
import { FlowError, FlowText } from "./lib/types";
import Offer from "./steps/Offer";

type OfferStepProps = {
  offer: FlowOfferFragment;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  flowText: FlowText;
  onImageLoad?: () => void;
  swappableProducts?: FlowSubscriptionFragment | null;
  canReschedule?: boolean;
  showDeclineButton?: boolean;
  displayMode?: "panel" | "modal" | "card";
  onAccept: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  disableVideoAutoPlay?: boolean;
  error?: FlowError;
};

const OfferStepContainer = styled.div<{ isPanel: boolean }>`
  ${tw`border border-divider p-8 ml-[1rem] mr-[2rem] bg-white overflow-y-auto`}

  ${(props) => props.isPanel && tw`min-w-[496px]`},
`;

const OfferStep: React.FunctionComponent<OfferStepProps> = ({
  offer,
  nextOrderDate,
  pauseReasons,
  flowText,
  onAccept,
  swappableProducts = null,
  canReschedule = true,
  disableVideoAutoPlay,
  onImageLoad = () => {},
  displayMode = "panel",
  error,
}) => (
  <OfferStepContainer isPanel={displayMode === "panel"}>
    <Offer
      offer={offer}
      nextOrderDate={nextOrderDate}
      pauseReasons={pauseReasons}
      flowText={flowText}
      onAccept={onAccept}
      swappableProducts={swappableProducts}
      canReschedule={canReschedule}
      disableVideoAutoPlay={disableVideoAutoPlay}
      onImageLoad={onImageLoad}
      error={error}
    />
  </OfferStepContainer>
);

export default OfferStep;
