import { gql, useMutation, useQuery } from "@apollo/client";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { theme } from "twin.macro";

import { defaultContentValues } from "../../../__generated__/editor";
import {
  AppEligibilityMessageFragment,
  EligibilityMessageFragment,
  EligibilityMessagePanelCreateEligibilityMessageMutation,
  EligibilityMessagePanelCreateEligibilityMessageMutationVariables,
  EligibilityMessagePanelInitEligibilityMessageContentMutation,
  EligibilityMessagePanelInitEligibilityMessageContentMutationVariables,
  EligibilityMessagePanelQuery,
  EligibilityMessagePanelQueryVariables,
  language_enum,
  translation_value_format_enum,
} from "../../../__generated__/graphql";
import Button from "../../../common/form/Button";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import FlowPropertyFragment from "../../../common/fragments/FlowPropertyFragment";
import TranslationFragment from "../../../common/fragments/TranslationFragment";
import HelpBlock from "../../../common/HelpBlock";
import Panel, { PanelProps } from "../../../common/panel/Panel";
import PanelButtons from "../../../common/panel/PanelButtons";
import PanelFormBody from "../../../common/panel/PanelFormBody";
import PanelTitle from "../../../common/panel/PanelTitle";
import TranslationsProvider from "../../../common/translations/TranslationsProvider";
import translationValue from "../../../common/translationValue";
import useAccountFeatures from "../../../common/useAccountFeatures";
import usePrevious from "../../../common/usePrevious";
import useViewer from "../../../common/useViewer";
import { PropertyValuesProvider } from "../../properties/lib/propertyValues";
import EligibilityMessageForm, {
  EligibilityMessageFormValues,
} from "./EligibilityMessageForm";

type EligibilityMessagePanelProps = PanelProps & {
  eligibilityMessageId?: number;
  mode: "create" | "edit";
  onClose: (eligibilityMessageFragment?: EligibilityMessageFragment) => void;
};

const mapFormValues = (
  formValues: Partial<EligibilityMessageFormValues>,
  eligibilityMessage: AppEligibilityMessageFragment,
  enabledLanguages: language_enum[]
): TranslatedForms<EligibilityMessageFormValues> =>
  enabledLanguages.reduce<TranslatedForms<EligibilityMessageFormValues>>(
    (prev, language) => {
      const header = translationValue(
        eligibilityMessage.eligibility_header_translation,
        language,
        language
      );

      const content = translationValue(
        eligibilityMessage.eligibility_message_translation,
        language,
        language
      );

      const getStringifiedValue = (value: any, defaultValue: any) =>
        value
          ? JSON.stringify(value)
          : defaultValue
          ? JSON.stringify(defaultValue)
          : undefined;

      return {
        ...prev,
        [language]: {
          ...formValues,
          eligibilityMessageHeader: getStringifiedValue(
            header.value,
            defaultContentValues["flow_routes.eligibility_header.default"][
              language
            ]
          ),
          eligibilityMessageContent: getStringifiedValue(
            content.value,
            defaultContentValues["flow_routes.eligibility_message.default"][
              language
            ]
          ),
        },
      };
    },
    {}
  );

const getInitialFormValues = (
  eligibilityMessage: AppEligibilityMessageFragment,
  enabledLanguages: language_enum[]
): TranslatedForms<EligibilityMessageFormValues> =>
  mapFormValues(
    {
      eligibilityMessageName: eligibilityMessage.name,
    },
    eligibilityMessage,
    enabledLanguages
  );

const EligibilityMessagePanel: React.FunctionComponent<
  EligibilityMessagePanelProps
> = ({
  eligibilityMessageId = undefined,
  mode = "create",
  onClose,
  ...props
}) => {
  const { addToast } = useToasts();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { viewer } = useViewer();

  const { features } = useAccountFeatures();

  const prevIsOpen = usePrevious(props.isOpen);

  const [enabledLanguages, setEnabledLanguages] = useState<language_enum[]>([
    language_enum.en_us,
  ]);
  const [formValues, setFormValues] =
    useState<TranslatedForms<EligibilityMessageFormValues>>();

  const { data, loading, refetch } = useQuery<
    EligibilityMessagePanelQuery,
    EligibilityMessagePanelQueryVariables
  >(
    gql`
      query EligibilityMessagePanelQuery($id: Int!) {
        eligibility_message_by_pk(id: $id) {
          id
          name
          eligibility_message_translation {
            ...TranslationFragment
          }
          eligibility_header_translation {
            ...TranslationFragment
          }
          account {
            properties {
              ...FlowPropertyFragment
            }
          }
        }

        flow {
          id
          default_language
          flow_languages {
            flow_id
            language
          }
          account {
            id
          }
        }
      }
      ${TranslationFragment}
      ${FlowPropertyFragment}
    `,
    {
      variables: { id: eligibilityMessageId || 0 },
      fetchPolicy: "cache-and-network",
    }
  );

  const eligibilityMessage = data?.eligibility_message_by_pk;

  const flow = (data?.flow.length && data.flow[0]) || undefined;
  const defaultLanguage = flow?.default_language || language_enum.en_us;
  const [editingLanguage, setEditingLanguage] = useState(defaultLanguage);

  useEffect(() => {
    if (!prevIsOpen && props.isOpen) {
      refetch();
    }
  }, [prevIsOpen, props, refetch]);

  const initialFormValues = useMemo(() => {
    if (!eligibilityMessage || !enabledLanguages.length) {
      return null;
    }
    return getInitialFormValues(eligibilityMessage, enabledLanguages);
  }, [eligibilityMessage, enabledLanguages]);

  useEffect(() => {
    if (eligibilityMessage && enabledLanguages.length) {
      setFormValues(getInitialFormValues(eligibilityMessage, enabledLanguages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibilityMessage, enabledLanguages]);

  useEffect(() => {
    if (data?.flow.length && !!features.translations) {
      let languages: language_enum[] = [];
      for (const flow of data.flow) {
        for (const language of flow.flow_languages) {
          if (languages.includes(language.language)) {
            continue;
          }
          languages.push(language.language);
        }
      }

      setEnabledLanguages(languages);
    }
  }, [data, features.translations]);

  useEffect(() => {
    setEditingLanguage(defaultLanguage);
  }, [defaultLanguage, setEditingLanguage]);

  const [initEligibilityMessageTranslation] = useMutation<
    EligibilityMessagePanelInitEligibilityMessageContentMutation,
    EligibilityMessagePanelInitEligibilityMessageContentMutationVariables
  >(gql`
    mutation EligibilityMessagePanelInitEligibilityMessageContentMutation(
      $objects: [translation_insert_input!]!
    ) {
      insert_translation(objects: $objects) {
        returning {
          id
        }
      }
    }
  `);

  const [saveEligibilityMessage] = useMutation<
    EligibilityMessagePanelCreateEligibilityMessageMutation,
    EligibilityMessagePanelCreateEligibilityMessageMutationVariables
  >(gql`
    mutation EligibilityMessagePanelCreateEligibilityMessageMutation(
      $object: eligibility_message_insert_input!
      $translationValues: [translation_value_insert_input!]!
    ) {
      insert_eligibility_message_one(
        object: $object
        on_conflict: {
          constraint: eligibility_message_pkey
          update_columns: [
            name
            eligibility_message_translation_id
            eligibility_header_translation_id
          ]
        }
      ) {
        id
        name
        eligibility_message_translation_id
        eligibility_header_translation_id
      }

      insert_translation_value(
        objects: $translationValues
        on_conflict: {
          constraint: translation_value_pkey
          update_columns: [value]
        }
      ) {
        returning {
          translation_id
          language
          value
          translation {
            ...TranslationFragment
          }
        }
      }
    }
    ${TranslationFragment}
  `);

  const handleFormChange = (
    newFormValues: TranslatedForms<EligibilityMessageFormValues>
  ) => {
    if (JSON.stringify(newFormValues) !== JSON.stringify(formValues)) {
      setFormValues(cloneDeep(newFormValues));
    }
  };

  const handleSubmit = async (
    forms: TranslatedForms<EligibilityMessageFormValues>
  ) => {
    if (!viewer) {
      throw new Error("No viewer");
    }

    setIsSubmitting(true);

    const values = forms[defaultLanguage];
    if (!values) {
      throw new Error();
    }

    const newHeaderTranslation = !eligibilityMessage
      ?.eligibility_header_translation?.id
      ? await initEligibilityMessageTranslation({
          variables: {
            objects: [
              {
                translation_values: {
                  data: Object.entries(forms)
                    .map(([language, values]) => ({
                      language: language as language_enum,
                      value: values?.eligibilityMessageHeader,
                      format: translation_value_format_enum.text,
                    }))
                    .filter((v) => !!v.value),
                },
              },
            ],
          },
        })
      : null;

    const headerTranslationId =
      newHeaderTranslation?.data?.insert_translation?.returning[0].id ||
      eligibilityMessage?.eligibility_header_translation?.id;

    const newTranslation = !eligibilityMessage?.eligibility_message_translation
      ?.id
      ? await initEligibilityMessageTranslation({
          variables: {
            objects: [
              {
                translation_values: {
                  data: Object.entries(forms)
                    .map(([language, values]) => ({
                      language: language as language_enum,
                      value: values?.eligibilityMessageContent,
                      format: translation_value_format_enum.text,
                    }))
                    .filter((v) => !!v.value),
                },
              },
            ],
          },
        })
      : null;

    const translationId =
      newTranslation?.data?.insert_translation?.returning[0].id ||
      eligibilityMessage?.eligibility_message_translation?.id;

    const contentValues = !!translationId
      ? Object.entries(forms)
          .map(([language, values]) => ({
            translation_id: translationId,
            language: language as language_enum,
            value: values?.eligibilityMessageContent
              ? JSON.parse(values.eligibilityMessageContent)
              : "",
            format: translation_value_format_enum.text,
          }))
          .filter((v) => !!v.value)
      : [];

    const headerValues = !!headerTranslationId
      ? Object.entries(forms)
          .map(([language, values]) => ({
            translation_id: headerTranslationId,
            language: language as language_enum,
            value: values?.eligibilityMessageHeader
              ? JSON.parse(values.eligibilityMessageHeader)
              : "",
            format: translation_value_format_enum.text,
          }))
          .filter((v) => !!v.value)
      : [];

    const translationValues = contentValues.concat(headerValues);

    const savedMessage = await saveEligibilityMessage({
      variables: {
        object: {
          id:
            mode === "edit" && eligibilityMessageId
              ? eligibilityMessageId
              : undefined,
          account_id: viewer?.account?.id,
          name: values.eligibilityMessageName,
          eligibility_message_translation_id: translationId,
          eligibility_header_translation_id: headerTranslationId,
        },
        translationValues: translationValues,
      },
    });

    addToast(<div>Eligibility message saved successfully.</div>, {
      appearance: "success",
    });

    if (mode === "edit") {
      onClose();
    } else {
      onClose(savedMessage.data?.insert_eligibility_message_one || undefined);
    }
  };

  const propertyConfig = (eligibilityMessage?.account.properties || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: {
        name: current.name,
        type: current.type,
        numberFormat: current.format,
      },
    }),
    {}
  );

  return (
    <>
      <Panel
        {...props}
        isLoading={loading && !eligibilityMessage}
        width={750}
        header={
          <>
            <PanelTitle>Eligibility message</PanelTitle>
            <PanelButtons>
              <Button
                buttonType="primary"
                form="eligibility-message"
                isLoading={isSubmitting}
              >
                Save
              </Button>
              <Button
                type="button"
                buttonType="default"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </PanelButtons>
          </>
        }
      >
        <PanelFormBody>
          <HelpBlock
            color="gray"
            content={
              <>
                An eligibility message is shown to your users prior to a flow if
                they are unable to cancel.
              </>
            }
            icon={
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={theme`colors.gray.400`}
              />
            }
            size="sm"
            tw="my-4"
          />
          <TranslationsProvider
            language={editingLanguage}
            defaultLanguage={defaultLanguage}
            enabledLanguages={enabledLanguages || [language_enum.en_us]}
          >
            <PropertyValuesProvider
              propertyValues={{}}
              propertyConfig={propertyConfig}
              showPlaceholders={true}
            >
              <EligibilityMessageForm
                properties={eligibilityMessage?.account.properties || []}
                initialValues={
                  initialFormValues as TranslatedForms<EligibilityMessageFormValues>
                }
                isSubmitting={isSubmitting}
                onChange={handleFormChange}
                onSubmit={handleSubmit}
                onChangeEditingLanguage={(language) =>
                  setEditingLanguage(language)
                }
              />
            </PropertyValuesProvider>
          </TranslationsProvider>
        </PanelFormBody>
      </Panel>
    </>
  );
};

export default EligibilityMessagePanel;
