import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import { TrialExtensionOfferFormValues } from "../types";

interface TrialExtensionDetailsFormPartialProps {
  mode: FormMode;
  isSubmitting: boolean;
}

const TrialExtensionDetailsFormPartial: React.FunctionComponent<
  TrialExtensionDetailsFormPartialProps
> = ({ mode, isSubmitting }) => {
  const { register, formState } =
    useTranslatableFormContext<TrialExtensionOfferFormValues>();

  return (
    <FieldSet
      title="Extension details"
      disabled={isSubmitting || mode === "edit"}
    >
      <FieldRow>
        <FieldLabel>
          <label>Days</label>
        </FieldLabel>
        <FieldInput>
          <TextInput
            {...register("days", {
              required: true,
              validate: (value) => Number(value) > 0,
            })}
            id="days"
            width="xs"
            fieldError={formState.errors.days}
          />
          <FieldHint>Days by which to extend the trial.</FieldHint>
        </FieldInput>
      </FieldRow>
    </FieldSet>
  );
};

export default TrialExtensionDetailsFormPartial;
