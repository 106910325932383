import classNames from "classnames";
import { styled } from "twin.macro";

import { offer_goal_enum } from "../../../../__generated__/graphql";

const OfferOptions = styled.div.attrs(
  (props: {
    className?: string;
    isBanner?: boolean;
    goal?: offer_goal_enum;
  }) => ({
    className: classNames(
      "flow-offer-options",
      props.isBanner && "flow-offer-options--banner",
      props.goal === offer_goal_enum.conversion &&
        "flow-offer-options--conversion",
      props.className
    ),
  })
)<{
  className: string;
  isBanner: boolean;
  goal: offer_goal_enum;
}>``;

export default OfferOptions;
