import { Controller } from "react-hook-form";
import { css } from "twin.macro";

import { defaultContentValues } from "../../../__generated__/editor";
import {
  FlowPropertyFragment,
  language_enum,
} from "../../../__generated__/graphql";
import Editor from "../../../common/editor2/Editor";
import { isContentEmpty } from "../../../common/editor2/lib";
import FieldInput from "../../../common/form/FieldInput";
import FieldLabel from "../../../common/form/FieldLabel";
import FieldRow from "../../../common/form/FieldRow";
import FieldRowBlock from "../../../common/form/FieldRowBlock";
import TextInput from "../../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../../common/form/useTranslatableForm";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import { PropertyValuesProvider } from "../../properties/lib/propertyValues";
import LanguageRadio from "./LanguageRadio";

export interface EligibilityMessageFormValues {
  eligibilityMessageName: string;
  eligibilityMessageHeader: string;
  eligibilityMessageContent: string;
}

interface EligibilityMessageFormProps {
  properties: FlowPropertyFragment[];
  initialValues?: TranslatedForms<EligibilityMessageFormValues>;
  isSubmitting: boolean;
  onChange: (values: TranslatedForms<EligibilityMessageFormValues>) => void;
  onSubmit: (values: TranslatedForms<EligibilityMessageFormValues>) => void;
  onChangeEditingLanguage: (language: language_enum) => void;
}

const getDefaultValues = (languages: language_enum[]) =>
  languages.reduce<TranslatedForms<EligibilityMessageFormValues>>(
    (prev, language) => ({
      ...prev,
      [language]: {
        eligibilityMessageName: "",
        eligibilityMessageHeader: JSON.stringify(
          defaultContentValues["flow_routes.eligibility_header.default"][
            language
          ]
        ),
        eligibilityMessageContent: JSON.stringify(
          defaultContentValues["flow_routes.eligibility_message.default"][
            language
          ]
        ),
      } as EligibilityMessageFormValues,
    }),
    {}
  );

const EligibilityMessageForm: React.FunctionComponent<
  EligibilityMessageFormProps
> = ({
  properties,
  initialValues,
  isSubmitting,
  onChange,
  onSubmit,
  onChangeEditingLanguage,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();

  const defaultValues = initialValues || getDefaultValues(enabledLanguages);

  const {
    register,
    forms,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
  } = useTranslatableForm<EligibilityMessageFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: [
      "eligibilityMessageContent",
      "eligibilityMessageHeader",
    ],
  });

  const propertyConfig = properties.reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: {
        name: current.name,
        type: current.type,
        numberFormat: current.format,
      },
    }),
    {}
  );

  return (
    <form id="eligibility-message" onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isSubmitting}>
        <FieldRow>
          <FieldLabel
            css={css`
              width: 20%;
            `}
          >
            <label>Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("eligibilityMessageName", { required: true })}
              width="full"
            />
          </FieldInput>
        </FieldRow>
        <FieldRowBlock>
          {enabledLanguages.length > 1 && (
            <LanguageRadio
              value={editingLanguage}
              languages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              onChange={(language) => {
                setEditingLanguage(language);
                onChangeEditingLanguage(language);
              }}
            />
          )}

          <FieldLabel
            css={css`
              width: 20%;
              padding-top: 20px;
            `}
          >
            <label>Header</label>
          </FieldLabel>

          <PropertyValuesProvider
            propertyValues={{}}
            propertyConfig={propertyConfig}
            showPlaceholders={true}
          >
            <Controller
              key={editingLanguage}
              control={control}
              name="eligibilityMessageHeader"
              render={({ field }) => (
                <Editor
                  initialValue={
                    forms[editingLanguage]?.eligibilityMessageHeader ||
                    undefined
                  }
                  initialValueKey={editingLanguage}
                  videosEnabled={false}
                  isInline={true}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  fieldError={formState.errors.eligibilityMessageHeader}
                />
              )}
            />
          </PropertyValuesProvider>

          <FieldLabel
            css={css`
              width: 20%;
              padding-top: 20px;
            `}
          >
            <label>Message</label>
          </FieldLabel>

          <PropertyValuesProvider
            propertyValues={{}}
            propertyConfig={propertyConfig}
            showPlaceholders={true}
          >
            <Controller
              key={editingLanguage}
              control={control}
              name="eligibilityMessageContent"
              render={({ field }) => (
                <Editor
                  height="10rem"
                  initialValue={
                    forms[editingLanguage]?.eligibilityMessageContent ||
                    undefined
                  }
                  initialValueKey={editingLanguage}
                  videosEnabled={false}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  fieldError={formState.errors.eligibilityMessageContent}
                />
              )}
              rules={{
                validate: (value) =>
                  !!value && !isContentEmpty(JSON.parse(value))
                    ? true
                    : "Content is required.",
              }}
            />
          </PropertyValuesProvider>
        </FieldRowBlock>
      </fieldset>
    </form>
  );
};

export default EligibilityMessageForm;
