import classNames from "classnames";
import { styled } from "twin.macro";

const OfferOptionsSection = styled.div.attrs(
  (props: { className?: string }) => ({
    className: classNames("flow-offer-options__section", props.className),
  })
)<{
  className: string;
}>``;

export default OfferOptionsSection;
