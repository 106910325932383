import classNames from "classnames";

import { useCountdown } from "../lib/useCountdown";

interface CountdownTimerProps {
  targetDate: string | number;
  banner?: boolean;
}

const CountdownTimer: React.FunctionComponent<CountdownTimerProps> = ({
  targetDate,
  banner = false,
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const wrapperClassNames = classNames(
    "flow-timer__wrapper",
    banner && "flow-timer__wrapper--banner"
  );

  const countClassNames = classNames(
    "flow-timer__count",
    banner && "flow-timer__count--banner"
  );

  const labelClassNames = classNames(
    "flow-timer__label",
    banner && "flow-timer__label--banner"
  );

  return (
    <div
      className={classNames(
        "flow-timer",
        banner ? "flow-timer--banner" : "flow-timer--modal"
      )}
    >
      <div className={wrapperClassNames}>
        <div className={countClassNames}>
          {days.toString().padStart(2, "0")}
        </div>
        <div className={labelClassNames}>days</div>
      </div>
      <div className={wrapperClassNames}>
        <div className={countClassNames}>
          {hours.toString().padStart(2, "0")}
        </div>
        <div className={labelClassNames}>hours</div>
      </div>
      <div className={wrapperClassNames}>
        <div className={countClassNames}>
          {minutes.toString().padStart(2, "0")}
        </div>
        <div className={labelClassNames}>mins</div>
      </div>
      <div className={wrapperClassNames}>
        <div className={countClassNames}>
          {seconds.toString().padStart(2, "0")}
        </div>
        <div className={labelClassNames}>secs</div>
      </div>
    </div>
  );
};

export default CountdownTimer;
