import { UnreachableCaseError } from "ts-essentials";

import {
  offer_goal_enum,
  offer_type_enum,
} from "../../../../__generated__/graphql";

const getTranslatableFields = (
  offerType: `${offer_type_enum}`,
  goal: `${offer_goal_enum}`
): string[] => {
  const baseFields =
    goal === "conversion"
      ? [
          "headline",
          "content",
          "buttonText",
          "confirmationContent",
          "confirmationConfirmButtonText",
          "confirmationCancelButtonText",
        ]
      : ["headline", "content", "buttonText"];

  switch (offerType) {
    case "pause_subscription":
      return [...baseFields, "option1Text", "option2Text", "option3Text"];

    case "change_plan":
    case "coupon":
    case "custom":
    case "downgrade":
    case "modify_subscription":
    case "product_swap":
    case "reschedule_order":
    case "trial_extension":
    case "upgrade":
      return baseFields;

    default:
      throw new UnreachableCaseError(offerType);
  }
};

export default getTranslatableFields;
