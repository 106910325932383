import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useState } from "react";

import Calendar from "../../../common/calendar/Calendar";
import OfferOption from "./ui/OfferOption";
import OfferOptionInput from "./ui/OfferOptionInput";
import OfferOptionLabel from "./ui/OfferOptionLabel";
import OfferOptionsFieldSet from "./ui/OfferOptionsFieldSet";

interface PauseDatesFormProps {
  startDate: Temporal.PlainDate;
  endDate: Temporal.PlainDate | undefined;
  minimumStartDate: Temporal.PlainDate;
  maximumStartDate: Temporal.PlainDate;
  maximumEndDate: Temporal.PlainDate;
  onChangeStartDate: (value: Temporal.PlainDate) => void;
  onChangeEndDate: (value: Temporal.PlainDate | undefined) => void;
}

const PauseDatesForm: React.FunctionComponent<PauseDatesFormProps> = ({
  startDate,
  endDate,
  onChangeStartDate,
  minimumStartDate,
  maximumStartDate,
  maximumEndDate,
  onChangeEndDate,
}) => {
  const [startIsOpen, setStartIsOpen] = useState(false);
  const [endIsOpen, setEndIsOpen] = useState(false);

  const handleChangeStartDate = (value: Temporal.PlainDate) => {
    onChangeStartDate(value);

    if (endDate && Temporal.PlainDate.compare(value, endDate) >= 0) {
      onChangeEndDate(undefined);
    }
  };

  return (
    <OfferOptionsFieldSet className={classNames(["flow-pause__dates"])}>
      <OfferOption className="flow-pause__dates__date flow-pause__dates__date-start">
        <OfferOptionLabel>Start pause on</OfferOptionLabel>
        <OfferOptionInput>
          <Tippy
            theme="light"
            visible={startIsOpen}
            onClickOutside={() => setStartIsOpen(false)}
            interactive={true}
            placement="bottom-start"
            offset={[0, 3]}
            maxWidth="none"
            content={
              <Calendar
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                  setStartIsOpen(false);
                }}
                minValue={minimumStartDate}
                maxValue={maximumStartDate}
                className="flow-pause__calendar"
              />
            }
            arrow={false}
          >
            <button
              className="flow-pause__calendar-toggle"
              onClick={() => setStartIsOpen(!startIsOpen)}
            >
              <div>
                {startDate.toLocaleString(undefined, {
                  dateStyle: "full",
                })}
              </div>
              <div tw="ml-auto">
                <FontAwesomeIcon icon={faCalendarDay} />
              </div>
            </button>
          </Tippy>
        </OfferOptionInput>
      </OfferOption>

      <OfferOption className="flow-pause__dates__date flow-pause__dates__date-end">
        <OfferOptionLabel>Resume on</OfferOptionLabel>
        <OfferOptionInput>
          <Tippy
            theme="light"
            visible={endIsOpen}
            onClickOutside={() => setEndIsOpen(false)}
            interactive={true}
            placement="bottom-start"
            offset={[0, 3]}
            maxWidth="none"
            content={
              <Calendar
                value={endDate}
                onChange={(value) => {
                  onChangeEndDate(value);
                  setEndIsOpen(false);
                }}
                minValue={startDate.add({ days: 1 })}
                maxValue={maximumEndDate}
                className="flow-pause__calendar"
              />
            }
            arrow={false}
          >
            <button
              className="flow-pause__calendar-toggle"
              onClick={() => setEndIsOpen(!endIsOpen)}
            >
              <div>
                {!!endDate
                  ? endDate.toLocaleString(undefined, {
                      dateStyle: "full",
                    })
                  : "Select a date"}
              </div>
              <div tw="ml-auto">
                <FontAwesomeIcon icon={faCalendarDay} />
              </div>
            </button>
          </Tippy>
        </OfferOptionInput>
      </OfferOption>
    </OfferOptionsFieldSet>
  );
};

export default PauseDatesForm;
