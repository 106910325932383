import { $isAtNodeEnd } from "@lexical/selection";
import { ElementNode, RangeSelection, TextNode } from "lexical";

export type EditorType = "slate" | "lexical";

const getSelectedNode = (selection: RangeSelection): TextNode | ElementNode => {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
};

const renderToString = (state: any) => {
  if (!state) {
    return "";
  }

  const text = [];

  if (state.root?.children) {
    if (!state.root.children.length) {
      return "";
    }
    for (const node of state.root.children) {
      if (node.children?.length) {
        for (const child of node.children) {
          text.push(child.text || "");
        }
      }
    }
  } else {
    for (const [, node] of state._nodeMap) {
      if (node.__type === "text") {
        text.push(node.__text);
      }
    }
  }

  return text.join();
};

const isContentNode = (node: any) => {
  if (
    node.type === "image" ||
    node.type === "video" ||
    node.type === "property" ||
    node.type === "offer_description"
  ) {
    return true;
  }

  if (node.children?.length) {
    for (const child of node.children) {
      if (isContentNode(child)) {
        return true;
      }
    }
  }

  return false;
};

const isContentEmpty = (state: any) => {
  if (!state) {
    return true;
  }

  if (typeof state === "string") {
    state = JSON.parse(state);
  }

  if (state.root?.children) {
    if (!state.root.children.length) {
      return true;
    }
    for (const node of state.root.children) {
      if (isContentNode(node)) {
        return false;
      }
    }
  } else {
    for (const [, node] of state._nodeMap) {
      if (
        node.__type === "image" ||
        node.__type === "video" ||
        node.__type === "property" ||
        node.__type === "offer_description"
      ) {
        return false;
      }
    }
  }

  return renderToString(state).trim().length < 1;
};

export { getSelectedNode, renderToString, isContentEmpty };
