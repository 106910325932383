import { gql, useMutation, useQuery } from "@apollo/client";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep, isEqual } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useToasts } from "react-toast-notifications";
import tw, { styled, theme } from "twin.macro";

import {
  AppPropertyFragment,
  AppSegmentGroupFragment,
  FlowRouteRuleFragment,
  FlowRoutesPanelQuery,
  FlowRoutesPanelSaveUnsavedChangesMutation,
  FlowRoutesPanelSaveUnsavedChangesMutationVariables,
  SegmentConditionFragment,
  SegmentFragment,
} from "../../__generated__/graphql";
import ConfirmationModal from "../../common/ConfirmationModal";
import Button from "../../common/form/Button";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import SelectInput from "../../common/form/input/SelectInput";
import TheFlowRouteRuleFragment from "../../common/fragments/FlowRouteRuleFragment";
import HelpBlock from "../../common/HelpBlock";
import HelpIcon from "../../common/HelpIcon";
import Panel, { PanelProps } from "../../common/panel/Panel";
import PanelBody from "../../common/panel/PanelBody";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelTitle from "../../common/panel/PanelTitle";
import RuleInlineSegmentFragment from "../../common/rules/fragments/RuleInlineSegmentFragment";
import RulePropertyFragment from "../../common/rules/fragments/RulePropertyFragment";
import useInsertInlineSegments from "../../common/rules/mutations/useInsertInlineSegments";
import { BoxHeading } from "../../common/rules/RuleBoxes";
import { RuleEditorFlowRouteRuleValue } from "../../common/rules/RuleEditor";
import StandardEternalLink from "../../common/StandardExternalLink";
import useAccountFeatures from "../../common/useAccountFeatures";
import useViewer from "../../common/useViewer";
import PropertyPanel from "../properties/PropertyPanel";
import CreateSegmentPanel from "../segments/CreateSegmentPanel";
import SegmentGroupPanel from "../segments/SegmentGroupPanel";
import usePaidFeature from "../upgrade-account/usePaidFeature";
import EligibilityMessagePanel from "./edit/EligibilityMessagePanel";
import FlowRoutes from "./FlowRoutes";

type FlowRoutesPanelProps = PanelProps & {
  onClose: () => void;
};

export interface Rule {
  segmentGroupIds: number[];
  segmentIds: number[];
  flowIds: number[];
  flowTestIds: number[];
}

export const defaultRule: FlowRouteRuleFragment = {
  __typename: "flow_route_rule",
  id: -1,
  segment_group_ids: [],
  segment_ids: [],
  flow_ids: [],
  flow_test_ids: [],
  eligibility_message_ids: [],
  flow_route_rule_segment_groups: [],
  flow_route_rule_segments: [],
  flow_route_rule_flows: [],
  flow_route_rule_flow_tests: [],
  flow_route_rule_eligibility_messages: [],
};

const dragHandleWidth = 40;
const deleteColumnWidth = 40;
const DeEpmphasizeColor = "#f9f9f9";

export const RuleHeaderDragColumn = styled.div`
  width: ${dragHandleWidth}px;
`;

export const RuleHeaderColumn = tw.div`text-gray-600 font-semibold p-2 w-1/2`;

export const RuleHeaderDeleteColumn = styled.div`
  width: ${deleteColumnWidth}px;
`;

export const RulesZeroState = styled.div`
  ${tw`p-10 text-center text-gray-600`}
`;

export const Rules = styled.div`
  &.rule-is-editing {
    ${tw`rounded`}
    background: ${DeEpmphasizeColor};
  }
`;

export const RuleRow = styled.div`
  ${tw`bg-white border-b border-gray-200 border-dashed last:border-b-0 last:rounded-b`}

  &.rule-is-dragging {
    ${tw`border border-solid rounded`}
  }

  &.rule-is-editing {
    ${tw`bg-white border border-solid border-gray-400 shadow-lg relative transition-all duration-200 rounded-none`}
    transform: scale(1.01);

    .rule-columns {
      ${tw`cursor-default`}
    }
  }

  &.rule-editing-is-disabled {
    ${tw`opacity-25 cursor-not-allowed`}
  }
`;

export const RuleBody = tw.div`flex`;

export const RuleColumns = tw.div`w-full flex cursor-pointer`;

export const RuleColumn = tw.div`p-2 w-1/2 text-gray-400`;

export const RuleInstructions = tw.div`text-sm`;

export const RuleDrag = styled.div`
  ${tw`flex justify-center`}
  width: ${dragHandleWidth}px;
`;

export const RuleDelete = styled.div`
  ${tw`flex justify-center text-red-500 pt-3`}
  width: ${deleteColumnWidth}px;

  button {
    ${tw`flex justify-center text-red-500 opacity-75 hover:opacity-100 focus:outline-none`}
  }
`;

export const DragHandle = styled.div`
  ${tw`text-gray-400 p-2 focus:outline-none`}

  &.is-disabled {
    ${tw`opacity-50`}
  }
`;

export const Box = styled.div<{ isEditable: boolean; isOn: boolean }>`
  ${tw`font-semibold px-2 rounded-md text-white m-1`}
  ${(props) => !props.isOn && tw`bg-gray-400!`}
  ${(props) => props.isEditable && tw`cursor-pointer`}
`;

export const AnyBox = styled(Box)`
  ${tw`bg-gray-800`}
`;

export const SegmentBox = styled(Box)`
  ${tw`bg-purple-600`}
`;

export const FlowBox = styled(Box)`
  ${tw`bg-orange-300`}
`;

export const FlowTestBox = styled(Box)`
  ${tw`bg-orange-400`}
`;

export const RuleEditButtons = styled.div`
  ${tw`pl-1 py-2 flex flex-row-reverse`}
  button {
    ${tw`ml-2`}
  }

  margin: 0 ${deleteColumnWidth}px 0 ${dragHandleWidth}px;
`;

const FlowRoutesPanel: React.FunctionComponent<FlowRoutesPanelProps> = ({
  onClose,
  ...props
}) => {
  const { features } = useAccountFeatures();

  const [stagedChanges, setStagedChanges] = useState<FlowRouteRuleFragment[]>(
    []
  );
  const [isSaving, setIsSaving] = useState(false);
  const [confirmSaveModalIsOpen, setConfirmSaveModalIsOpen] = useState(false);
  const [defaultFlowId, setDefaultFlowId] = useState<number>();
  const { addToast } = useToasts();
  const { viewer } = useViewer();

  const { setPaidFeatureRef, generateClassName } = usePaidFeature();
  const [createSegmentGroupPanelKey, setCreateSegmentGroupPanelKey] = useState(
    nanoid()
  );
  const [createSegmentGroupPanelIsOpen, setCreateSegmentGroupPanelIsOpen] =
    useState(false);

  const [createPropertyPanelIsOpen, setCreatePropertyPanelIsOpen] =
    useState(false);
  const [createPropertyPanelKey, setCreatePropertyPanelKey] = useState(
    nanoid()
  );
  const [newProperty, setNewProperty] = useState<AppPropertyFragment>();
  const [defaultPropertyEntity, setDefaultPropertyEntity] = useState<string>();

  const [createSegmentPanelKey, setCreateSegmentPanelKey] = useState(nanoid());
  const [createSegmentPanelIsOpen, setCreateSegmentPanelIsOpen] =
    useState(false);

  const [createMessagePanelKey, setCreateMessagePanelKey] = useState(nanoid());
  const [createMessagePanelIsOpen, setCreateMessagePanelIsOpen] =
    useState(false);
  const [editingMessageId, setEditingMessageId] = useState<number | undefined>(
    undefined
  );

  const [newConditions, setNewConditions] = useState<
    Record<number, SegmentConditionFragment[]>
  >({});

  const [editingId, setEditingId] = useState<number | null>(null);

  const insertInlineSegments = useInsertInlineSegments();

  const { data, refetch } = useQuery<FlowRoutesPanelQuery>(
    gql`
      query FlowRoutesPanelQuery {
        flow_route_rule(order_by: { position: asc }) {
          ...FlowRouteRuleFragment
        }

        segment_group {
          id
          name
          segment_group_segments {
            segment {
              id
              name
            }
          }
        }

        segment(where: { inline: { _eq: false } }, order_by: { name: asc }) {
          id
          name
          integration {
            id
            type
          }
        }

        inlineSegments: segment(where: { inline: { _eq: true } }) {
          ...RuleInlineSegmentFragment
        }

        property(where: { deleted_at: { _is_null: true } }) {
          ...RulePropertyFragment
        }

        flow(where: { deleted_at: { _is_null: true } }) {
          id
          title
        }

        flow_test(where: { deleted_at: { _is_null: true } }) {
          id
          name
        }

        eligibility_message(where: { deleted_at: { _is_null: true } }) {
          id
          name
        }
      }
      ${TheFlowRouteRuleFragment}
      ${RuleInlineSegmentFragment}
      ${RulePropertyFragment}
    `,
    { fetchPolicy: "cache-and-network" }
  );

  const [saveUnsavedChanges] = useMutation<
    FlowRoutesPanelSaveUnsavedChangesMutation,
    FlowRoutesPanelSaveUnsavedChangesMutationVariables
  >(gql`
    mutation FlowRoutesPanelSaveUnsavedChangesMutation(
      $flowRouteRules: [flow_route_rule_insert_input!]!
      $eligibilityRouteRules: [flow_route_rule_insert_input!]!
      $accountId: Int!
      $defaultFlowId: Int!
    ) {
      delete_flow_route_rule(where: {}) {
        affected_rows
      }

      insertFlowRoutes: insert_flow_route_rule(objects: $flowRouteRules) {
        affected_rows
      }

      insertEligibilityMessages: insert_flow_route_rule(
        objects: $eligibilityRouteRules
      ) {
        affected_rows
      }

      update_account_by_pk(
        pk_columns: { id: $accountId }
        _set: { default_flow_id: $defaultFlowId }
      ) {
        id
        default_flow_id
      }
    }
  `);

  const handleCreateSegmentGroup = async (
    newSegmentGroup: AppSegmentGroupFragment
  ) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      segmentGroupIds: [...ruleEditorValue.segmentGroupIds, newSegmentGroup.id],
    });
  };

  const handleCreateSegment = async (newSegment: SegmentFragment) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      segmentIds: [...ruleEditorValue.segmentIds, newSegment.id],
    });
  };

  const handleCreateMessage = async (newMessage: any) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      flowIds: [],
      flowTestIds: [],
      eligibilityMessageIds: [newMessage.id],
    });
  };

  const handleSaveChanges = async (confirmed: boolean) => {
    if (!account || !defaultFlowId) {
      return;
    }

    if (!confirmed) {
      setConfirmSaveModalIsOpen(true);
      return;
    }

    setIsSaving(true);

    const changesToSave = Array.from(cloneDeep(stagedChanges));

    for (const [index, rule] of changesToSave.entries()) {
      const conditions = newConditions[index] || [];
      if (conditions.length) {
        const inlineSegments = await insertInlineSegments(conditions);
        changesToSave[index].segment_ids = [
          ...rule.segment_ids,
          ...inlineSegments.map((s) => s.id),
        ];
      }
    }

    let position = 0;
    await saveUnsavedChanges({
      variables: {
        accountId: account.id,
        defaultFlowId,
        flowRouteRules: changesToSave
          .filter((r) => r.eligibility_message_ids.length === 0)
          .map((r) => ({
            position: position++,
            segment_group_ids: r.segment_group_ids,
            segment_ids: r.segment_ids,
            flow_ids: r.flow_ids,
            flow_test_ids: r.flow_test_ids,
            eligibility_message_ids: r.eligibility_message_ids,
          })),
        eligibilityRouteRules: changesToSave
          .filter((r) => r.eligibility_message_ids.length > 0)
          .map((r) => ({
            position: position++,
            segment_group_ids: r.segment_group_ids,
            segment_ids: r.segment_ids,
            flow_ids: r.flow_ids,
            flow_test_ids: r.flow_test_ids,
            eligibility_message_ids: r.eligibility_message_ids,
          })),
      },
    });

    await refetch();

    setIsSaving(false);
    setNewConditions({});

    addToast(<div>Flow route rules saved successfully.</div>, {
      appearance: "success",
    });

    onClose();
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    const reordered = Array.from(cloneDeep(stagedChanges));
    const [removed] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, removed);
    setStagedChanges(reordered);

    setNewConditions({
      ...newConditions,
      [result.source.index]: newConditions[result.destination.index] || [],
      [result.destination.index]: newConditions[result.source.index] || [],
    });
  };

  const account = viewer?.account;
  const platform = account?.platform_connection?.platform;
  const flows = data?.flow || [];

  const actualRules = data?.flow_route_rule || [];

  const [ruleEditorValue, setRuleEditorValue] =
    useState<RuleEditorFlowRouteRuleValue>();

  useEffect(() => {
    setStagedChanges(data?.flow_route_rule || []);
    setDefaultFlowId(account?.default_flow_id || undefined);
  }, [account?.default_flow_id, data?.flow_route_rule]);

  const hasUnsavedChanges = (() => {
    if (defaultFlowId !== account?.default_flow_id) {
      return true;
    }

    if (stagedChanges.length !== actualRules.length) {
      return true;
    }

    for (const [i, rule] of stagedChanges.entries()) {
      if (!actualRules[i]) {
        return true;
      }

      const match = actualRules[i];
      if (
        !isEqual(rule.segment_group_ids, match.segment_group_ids) ||
        !isEqual(rule.segment_ids, match.segment_ids) ||
        !isEqual(rule.flow_ids, match.flow_ids) ||
        !isEqual(rule.flow_test_ids, match.flow_test_ids) ||
        !isEqual(rule.eligibility_message_ids, match.eligibility_message_ids)
      ) {
        return true;
      }

      if (newConditions[i]?.length) {
        return true;
      }
    }

    return false;
  })();

  return (
    <>
      <Panel
        width={850}
        {...props}
        header={
          <>
            <PanelTitle>Flow routes</PanelTitle>
            <PanelButtons>
              <Button
                buttonType="primary"
                form="flow-routes"
                isLoading={isSaving}
                disabled={!hasUnsavedChanges}
                onClick={() => handleSaveChanges(false)}
              >
                Save
              </Button>
              <Button type="button" buttonType="default" onClick={onClose}>
                Cancel
              </Button>
            </PanelButtons>
          </>
        }
      >
        <PanelBody tw="h-full" ref={(ref) => setPaidFeatureRef(ref)}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {features.multiple_flows && (
              <>
                <HelpBlock
                  color="gray"
                  content={
                    <>
                      Route subscribers to a cancellation flow based on
                      segments. Rules are evaluated top down; the first matching
                      rule will determine the selected cancellation flow.{" "}
                      <StandardEternalLink
                        href="https://prosperstack.com/docs/multiple-flows/#routing-customers-to-a-flow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See the documentation.
                      </StandardEternalLink>
                    </>
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color={theme`colors.gray.400`}
                    />
                  }
                  size="sm"
                  tw="my-4"
                />
                <div tw="mb-4">
                  <FieldRow
                    tw="border-divider px-2"
                    className={generateClassName(
                      "Upgrade to create multiple flows.",
                      "panel"
                    )}
                  >
                    <FieldLabel>
                      <label>Default flow</label>
                    </FieldLabel>
                    <FieldInput>
                      <SelectInput
                        value={defaultFlowId}
                        onChange={(e) => {
                          setDefaultFlowId(Number(e.currentTarget.value));
                        }}
                      >
                        {flows.map((f) => (
                          <option key={f.id} value={f.id}>
                            {f.title}
                          </option>
                        ))}
                      </SelectInput>
                      <FieldHint>
                        Default flow to use when no rule matches.
                      </FieldHint>
                    </FieldInput>
                  </FieldRow>
                </div>
                <BoxHeading tw="text-gray-400 font-bold">Flow Rules</BoxHeading>
                <FlowRoutes
                  type="flow"
                  flowRoutesData={data}
                  isSaving={isSaving}
                  setIsSaving={(saving) => setIsSaving(saving)}
                  editingId={editingId}
                  setEditingId={(id) => setEditingId(id)}
                  refetch={() => refetch()}
                  stagedChanges={stagedChanges}
                  setStagedChanges={(changed) => setStagedChanges(changed)}
                  ruleEditorValue={ruleEditorValue}
                  setRuleEditorValue={(v) => setRuleEditorValue(v)}
                  onCreateSegmentGroup={() =>
                    setCreateSegmentGroupPanelIsOpen(true)
                  }
                  onCreateSegment={() => setCreateSegmentPanelIsOpen(true)}
                  onCreateMessage={(id) => {
                    setEditingMessageId(id);
                    setCreateMessagePanelIsOpen(true);
                  }}
                  newConditions={newConditions}
                  setNewConditions={(conditions) =>
                    setNewConditions(conditions)
                  }
                />
              </>
            )}

            {features.eligibility_messages && (
              <>
                {!features.multiple_flows && (
                  <HelpBlock
                    color="gray"
                    content={
                      <>
                        An eligibility message is shown to your users prior to a
                        flow if they are unable to cancel.
                      </>
                    }
                    icon={
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color={theme`colors.gray.400`}
                      />
                    }
                    size="sm"
                    tw="my-4"
                  />
                )}
                <BoxHeading tw="text-gray-400 font-bold">
                  Eligibility Messages{" "}
                  {features.multiple_flows && (
                    <>
                      <HelpIcon
                        tw=""
                        content="An eligibility message is shown to your users prior to a flow if they are unable to cancel."
                      />
                    </>
                  )}
                </BoxHeading>
                <FlowRoutes
                  type="eligibility_message"
                  flowRoutesData={data}
                  isSaving={isSaving}
                  setIsSaving={(saving) => setIsSaving(saving)}
                  editingId={editingId}
                  setEditingId={(id) => setEditingId(id)}
                  refetch={() => refetch()}
                  stagedChanges={stagedChanges}
                  setStagedChanges={(changed) => setStagedChanges(changed)}
                  ruleEditorValue={ruleEditorValue}
                  setRuleEditorValue={(v) => setRuleEditorValue(v)}
                  onCreateSegmentGroup={() =>
                    setCreateSegmentGroupPanelIsOpen(true)
                  }
                  onCreateSegment={() => setCreateSegmentPanelIsOpen(true)}
                  onCreateMessage={(id) => {
                    setEditingMessageId(id);
                    setCreateMessagePanelIsOpen(true);
                  }}
                  newConditions={newConditions}
                  setNewConditions={(conditions) =>
                    setNewConditions(conditions)
                  }
                />
              </>
            )}
          </DragDropContext>
        </PanelBody>
      </Panel>
      <PropertyPanel
        key={createPropertyPanelKey}
        mode="create"
        isOpen={createPropertyPanelIsOpen}
        onClose={async (property) => {
          setCreatePropertyPanelIsOpen(false);
          if (property) {
            setNewProperty(property);
          }

          setTimeout(() => {
            setCreatePropertyPanelKey(nanoid());
          }, 500);
        }}
        defaultEntity={defaultPropertyEntity}
      />
      {platform && (
        <CreateSegmentPanel
          key={createSegmentPanelKey}
          mode="create"
          isOpen={createSegmentPanelIsOpen && !createPropertyPanelIsOpen}
          platform={platform}
          onClickCreateProperty={(entity) => {
            setDefaultPropertyEntity(entity);
            setCreatePropertyPanelIsOpen(true);
          }}
          newProperty={newProperty}
          onClose={(newSegment) => {
            setCreateSegmentPanelIsOpen(false);
            window.setTimeout(() => {
              setCreateSegmentPanelKey(nanoid());
            }, 733);

            if (newSegment) {
              handleCreateSegment(newSegment);
            }
          }}
        />
      )}
      <SegmentGroupPanel
        key={createSegmentGroupPanelKey}
        mode="create"
        isOpen={createSegmentGroupPanelIsOpen}
        onClose={(newSegmentGroup) => {
          setCreateSegmentGroupPanelIsOpen(false);
          window.setTimeout(() => {
            setCreateSegmentGroupPanelKey(nanoid());
          }, 733);

          if (newSegmentGroup) {
            handleCreateSegmentGroup(newSegmentGroup);
          }
        }}
      />
      <EligibilityMessagePanel
        key={createMessagePanelKey}
        mode={!!editingMessageId ? "edit" : "create"}
        eligibilityMessageId={editingMessageId}
        isOpen={createMessagePanelIsOpen}
        onClose={(newMessage) => {
          setCreateMessagePanelIsOpen(false);
          window.setTimeout(() => {
            setCreateMessagePanelKey(nanoid());
          }, 733);

          if (newMessage) {
            handleCreateMessage(newMessage);
          }
        }}
      />
      <ConfirmationModal
        isOpen={confirmSaveModalIsOpen}
        title="Save flow route changes"
        content="Are you sure you want to save your flow route changes? Your changes will take effect immediately."
        confirmText="Save changes"
        confirmButtonType="primary"
        onClose={async (confirmed) => {
          setConfirmSaveModalIsOpen(false);

          if (confirmed) {
            handleSaveChanges(true);
          }
        }}
      />
    </>
  );
};

export default FlowRoutesPanel;
