import classNames from "classnames";
import { styled } from "twin.macro";

const OfferOptionLabel = styled.label.attrs(
  (props: { className?: string }) => ({
    className: classNames("flow-offer-option__label", props.className),
  })
)<{
  className: string;
}>``;

export default OfferOptionLabel;
