import { gql, useMutation } from "@apollo/client";
import {
  faGripVertical,
  faPlus,
  faTimesCircle,
  faVial,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useCallback, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { isPresent } from "ts-is-present";

import {
  FlowRouteRuleFragment,
  FlowRoutesDeleteEligibilityMessageMutation,
  FlowRoutesDeleteEligibilityMessageMutationVariables,
  FlowRoutesPanelQuery,
  SegmentConditionFragment,
} from "../../__generated__/graphql";
import Card from "../../common/card/Card";
import Button from "../../common/form/Button";
import {
  BoxContainer,
  EligibilityMessageBox,
} from "../../common/rules/RuleBoxes";
import RuleEditor, {
  RuleEditorFlowRouteRuleValue,
} from "../../common/rules/RuleEditor";
import RuleSegments from "../../common/rules/RuleSegments";
import StandardLinkButton from "../../common/StandardLinkButton";
import useAccountFeatures from "../../common/useAccountFeatures";
import useViewer from "../../common/useViewer";
import usePaidFeature from "../upgrade-account/usePaidFeature";
import { useUpsellBanner } from "../upgrade-account/useUpsellBanner";
import {
  DragHandle,
  FlowBox,
  FlowTestBox,
  RuleBody,
  RuleColumn,
  RuleColumns,
  RuleDelete,
  RuleDrag,
  RuleHeaderColumn,
  RuleHeaderDeleteColumn,
  RuleHeaderDragColumn,
  RuleRow,
  Rules,
  RulesZeroState,
} from "./FlowRoutesPanel";
import { RuleHeader } from "./lib/flowRoutesStyledComponents";

type FlowRoutesProps = {
  type: "flow" | "eligibility_message";
  flowRoutesData: FlowRoutesPanelQuery | undefined;
  isSaving: boolean;
  setIsSaving: (saving: boolean) => void;
  editingId: number | null;
  setEditingId: (id: number | null) => void;
  refetch: () => void;
  stagedChanges: FlowRouteRuleFragment[];
  newConditions: Record<number, SegmentConditionFragment[]>;
  setNewConditions: (
    newConditions: Record<number, SegmentConditionFragment[]>
  ) => void;
  ruleEditorValue: RuleEditorFlowRouteRuleValue | undefined;
  setRuleEditorValue: (v: RuleEditorFlowRouteRuleValue) => void;
  setStagedChanges: (changed: FlowRouteRuleFragment[]) => void;
  onCreateSegment: () => void;
  onCreateSegmentGroup: () => void;
  onCreateMessage: (id: number | undefined) => void;
};

export const defaultRule: FlowRouteRuleFragment = {
  __typename: "flow_route_rule",
  id: -1,
  segment_group_ids: [],
  segment_ids: [],
  flow_ids: [],
  flow_test_ids: [],
  eligibility_message_ids: [],
  flow_route_rule_segment_groups: [],
  flow_route_rule_segments: [],
  flow_route_rule_flows: [],
  flow_route_rule_flow_tests: [],
  flow_route_rule_eligibility_messages: [],
};

const FlowRoutes: React.FunctionComponent<FlowRoutesProps> = ({
  type,
  flowRoutesData,
  isSaving,
  setIsSaving,
  editingId,
  setEditingId,
  refetch,
  stagedChanges,
  newConditions,
  setNewConditions,
  ruleEditorValue,
  setRuleEditorValue,
  setStagedChanges,
  onCreateSegment,
  onCreateSegmentGroup,
  onCreateMessage,
  ...props
}) => {
  const { features } = useAccountFeatures();
  const { viewer } = useViewer();

  const [editingNewRule, setEditingNewRule] = useState(false);

  const { generateClassName } = usePaidFeature();
  const { enabled: isFreeMode } = useUpsellBanner(
    "Upgrade to access flow routes"
  );

  const account = viewer?.account;
  const platform = account?.platform_connection?.platform;
  const segments = flowRoutesData?.segment || [];
  const inlineSegments = flowRoutesData?.inlineSegments || [];
  const segmentGroups = flowRoutesData?.segment_group || [];
  const properties = flowRoutesData?.property || [];
  const flows = flowRoutesData?.flow || [];
  const flowTests = flowRoutesData?.flow_test || [];
  const eligibilityMessages = flowRoutesData?.eligibility_message || [];

  const nextId = useCallback(() => {
    if (stagedChanges.length < 1) {
      return 1;
    }

    return Math.max(...stagedChanges.map((r) => r.id)) + 1;
  }, [stagedChanges]);

  const setEditingRule = (rule: FlowRouteRuleFragment) => {
    setEditingId(rule.id);

    const ruleIndex = stagedChanges.findIndex((r) => r.id === rule.id);

    setRuleEditorValue({
      type: "flow_route_rule",
      segmentGroupIds: rule.segment_group_ids,
      segmentIds: rule.segment_ids,
      newConditions: newConditions[ruleIndex] || [],
      flowIds: rule.flow_ids,
      flowTestIds: rule.flow_test_ids,
      eligibilityMessageIds: rule.eligibility_message_ids,
    });
  };

  const handleAddRule = async (options?: {
    segmentGroupIds?: number[];
    segmentIds?: number[];
    flowIds?: number[];
    flowTestIds?: number[];
  }) => {
    if (editingId) {
      return;
    }

    const rule = { ...defaultRule, id: nextId() };

    setEditingRule(rule);
    setEditingNewRule(true);
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditingNewRule(false);
  };

  const rules: FlowRouteRuleFragment[] =
    ruleEditorValue && editingNewRule && editingId !== null
      ? [
          ...stagedChanges,
          {
            __typename: "flow_route_rule",
            id: editingId,
            segment_group_ids: ruleEditorValue.segmentGroupIds,
            segment_ids: ruleEditorValue.segmentIds,
            flow_ids: ruleEditorValue.flowIds,
            flow_test_ids: ruleEditorValue.flowTestIds,
            eligibility_message_ids: ruleEditorValue.eligibilityMessageIds,
            flow_route_rule_segment_groups: ruleEditorValue.segmentGroupIds.map(
              (id) => ({
                __typename: "flow_route_rule_segment_group",
                flow_route_rule_id: editingId,
                segment_group_id: id,
              })
            ),
            flow_route_rule_segments: ruleEditorValue.segmentIds.map((id) => ({
              __typename: "flow_route_rule_segment",
              flow_route_rule_id: editingId,
              segment_id: id,
            })),
            flow_route_rule_flows: ruleEditorValue.flowIds.map((id) => ({
              __typename: "flow_route_rule_flow",
              flow_route_rule_id: editingId,
              flow_id: id,
            })),
            flow_route_rule_flow_tests: ruleEditorValue.flowTestIds.map(
              (id) => ({
                __typename: "flow_route_rule_flow_test",
                flow_route_rule_id: editingId,
                flow_test_id: id,
              })
            ),
            flow_route_rule_eligibility_messages:
              ruleEditorValue.eligibilityMessageIds.map((id) => ({
                __typename: "flow_route_rule_eligibility_message",
                flow_route_rule_id: editingId,
                eligibility_message_id: id,
              })),
          },
        ]
      : stagedChanges;

  const undeletableMessageIds = (ruleId: number) => {
    const stagedIds = rules
      .filter((r) => r.id !== ruleId)
      .map((r) => r.eligibility_message_ids)
      .flat();

    const ids =
      flowRoutesData?.flow_route_rule
        .map((r) => r.eligibility_message_ids)
        .flat() || [];
    return [...new Set(stagedIds.concat(ids))];
  };

  const filteredRules = rules.filter((rule) => {
    if (
      type === "flow" &&
      rule.flow_route_rule_eligibility_messages.length > 0
    ) {
      return false;
    }

    if (
      type === "eligibility_message" &&
      (rule.flow_route_rule_flows.length > 0 ||
        rule.flow_route_rule_flow_tests.length > 0)
    ) {
      return false;
    }

    return true;
  });

  const editingRule = editingId
    ? stagedChanges.find((rule) => rule.id === editingId)
    : null;

  const handleSave = async () => {
    if (
      !ruleEditorValue ||
      !editingId ||
      (ruleEditorValue.flowIds.length < 1 &&
        ruleEditorValue.flowTestIds.length < 1 &&
        ruleEditorValue.eligibilityMessageIds.length < 1)
    ) {
      return;
    }

    setIsSaving(true);
    let ruleIndex = -1;

    if (editingNewRule) {
      ruleIndex = stagedChanges.length;

      setStagedChanges([
        ...stagedChanges,
        {
          __typename: "flow_route_rule",
          id: editingId,
          segment_group_ids: ruleEditorValue.segmentGroupIds,
          segment_ids: ruleEditorValue.segmentIds,
          flow_ids: ruleEditorValue.flowIds,
          flow_test_ids: ruleEditorValue.flowTestIds,
          eligibility_message_ids: ruleEditorValue.eligibilityMessageIds,
          flow_route_rule_segment_groups: ruleEditorValue.segmentGroupIds.map(
            (id) => ({
              __typename: "flow_route_rule_segment_group",
              flow_route_rule_id: editingId,
              segment_group_id: id,
            })
          ),
          flow_route_rule_segments: ruleEditorValue.segmentIds.map((id) => ({
            __typename: "flow_route_rule_segment",
            flow_route_rule_id: editingId,
            segment_id: id,
          })),
          flow_route_rule_flows: ruleEditorValue.flowIds.map((id) => ({
            __typename: "flow_route_rule_flow",
            flow_route_rule_id: editingId,
            flow_id: id,
          })),
          flow_route_rule_flow_tests: ruleEditorValue.flowTestIds.map((id) => ({
            __typename: "flow_route_rule_flow_test",
            flow_route_rule_id: editingId,
            flow_test_id: id,
          })),
          flow_route_rule_eligibility_messages:
            ruleEditorValue.eligibilityMessageIds.map((id) => ({
              __typename: "flow_route_rule_eligibility_message",
              flow_route_rule_id: editingId,
              eligibility_message_id: id,
            })),
        },
      ]);
    } else if (editingRule) {
      ruleIndex = stagedChanges.findIndex((r) => r.id === editingRule.id);
      if (ruleIndex < 0) {
        throw new Error(`Rule ${editingRule.id} not found`);
      }

      setStagedChanges([
        ...stagedChanges.slice(0, ruleIndex),
        {
          __typename: "flow_route_rule",
          id: nextId(),
          segment_group_ids: ruleEditorValue.segmentGroupIds,
          segment_ids: ruleEditorValue.segmentIds,
          flow_ids: ruleEditorValue.flowIds,
          flow_test_ids: ruleEditorValue.flowTestIds,
          eligibility_message_ids: ruleEditorValue.eligibilityMessageIds,
          flow_route_rule_segment_groups: ruleEditorValue.segmentGroupIds.map(
            (id) => ({
              __typename: "flow_route_rule_segment_group",
              flow_route_rule_id: nextId(),
              segment_group_id: id,
            })
          ),
          flow_route_rule_segments: ruleEditorValue.segmentIds.map((id) => ({
            __typename: "flow_route_rule_segment",
            flow_route_rule_id: nextId(),
            segment_id: id,
          })),
          flow_route_rule_flows: ruleEditorValue.flowIds.map((id) => ({
            __typename: "flow_route_rule_flow",
            flow_route_rule_id: nextId(),
            flow_id: id,
          })),
          flow_route_rule_flow_tests: ruleEditorValue.flowTestIds.map((id) => ({
            __typename: "flow_route_rule_flow_test",
            flow_route_rule_id: nextId(),
            flow_test_id: id,
          })),
          flow_route_rule_eligibility_messages:
            ruleEditorValue.eligibilityMessageIds.map((id) => ({
              __typename: "flow_route_rule_eligibility_message",
              flow_route_rule_id: nextId(),
              eligibility_message_id: id,
            })),
        },
        ...stagedChanges.slice(ruleIndex + 1),
      ]);
    }

    setNewConditions({
      ...newConditions,
      [ruleIndex]: [...ruleEditorValue.newConditions],
    });

    setEditingId(null);
    setEditingNewRule(false);

    refetch();

    setIsSaving(false);
  };

  const [deleteMessage] = useMutation<
    FlowRoutesDeleteEligibilityMessageMutation,
    FlowRoutesDeleteEligibilityMessageMutationVariables
  >(gql`
    mutation FlowRoutesDeleteEligibilityMessageMutation($id: Int!) {
      update_eligibility_message_by_pk(
        pk_columns: { id: $id }
        _set: { deleted_at: "now" }
      ) {
        id
      }
    }
  `);

  const handleDeleteMessage = async (id: number) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await deleteMessage({ variables: { id } });
    refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      eligibilityMessageIds: ruleEditorValue.eligibilityMessageIds.filter(
        (m) => m !== id
      ),
    });
  };

  const handleClickRule = (ruleId: number) => {
    if (!editingId) {
      const rule = stagedChanges.find((rule) => rule.id === ruleId);

      if (rule) {
        setEditingNewRule(false);
        setEditingRule(rule);
      }
    }
  };

  const handleDeleteRule = async (ruleId: number) => {
    const ruleIndex = stagedChanges.findIndex((r) => r.id === ruleId);
    if (ruleIndex < 0) {
      throw new Error(`Rule ${ruleId} not found`);
    }

    setStagedChanges([
      ...stagedChanges.slice(0, ruleIndex),
      ...stagedChanges.slice(ruleIndex + 1),
    ]);

    const newNewConditions = { ...newConditions };

    let actualIndex = 0;
    for (const [index] of stagedChanges.entries()) {
      if (index === ruleIndex) {
        continue;
      } else {
        newNewConditions[actualIndex] = newConditions[index];
      }

      actualIndex++;
    }

    setNewConditions(newNewConditions);
  };

  return (
    <div tw="pb-8">
      <Card border tw="rounded p-0">
        <RuleHeader
          tw="rounded-t"
          className={editingId ? "rule-is-editing" : undefined}
        >
          <RuleHeaderDragColumn></RuleHeaderDragColumn>
          <RuleHeaderColumn>Condition</RuleHeaderColumn>
          <RuleHeaderColumn>
            {type === "flow" ? "Flow" : "Eligibility message"}
          </RuleHeaderColumn>
          <RuleHeaderDeleteColumn></RuleHeaderDeleteColumn>
        </RuleHeader>
        {filteredRules.length === 0 &&
          (type === "flow" ? (
            <RulesZeroState
              className={generateClassName(
                "Upgrade to create flow routing rules based on customer segments.",
                "panel"
              )}
            >
              Route to cancellation flow based on segments. Rules define which
              customers will be routed to which cancellation flows.{" "}
              <StandardLinkButton
                onClick={() => (!isFreeMode ? handleAddRule() : undefined)}
              >
                Create a rule.
              </StandardLinkButton>
            </RulesZeroState>
          ) : (
            <RulesZeroState
              className={generateClassName(
                "Upgrade to create cancellation message routing rules based on customer segments.",
                "panel"
              )}
            >
              Route to an elibility message based on segments. Rules define
              which customers will be routed to which eligiblity messages.{" "}
              <StandardLinkButton
                onClick={() => (!isFreeMode ? handleAddRule() : undefined)}
              >
                Create a rule.
              </StandardLinkButton>
            </RulesZeroState>
          ))}

        <Droppable droppableId="flowRoutes">
          {(provided, droppableSnapshot) => (
            <Rules
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classNames({ "rule-is-editing": !!editingId })}
            >
              {rules.map(
                (rule, index) =>
                  ((type === "flow" &&
                    rule.flow_route_rule_eligibility_messages.length === 0) ||
                    (type === "eligibility_message" &&
                      rule.flow_route_rule_flows.length === 0 &&
                      rule.flow_route_rule_flow_tests.length === 0)) && (
                    <Draggable
                      key={rule.id}
                      draggableId={rule.id.toString()}
                      index={index}
                      isDragDisabled={!!editingId || rules.length === 1}
                    >
                      {(provided, snapshot) => {
                        const isEditing = rule.id === editingId;
                        return (
                          <RuleRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classNames({
                              "rule-is-dragging": snapshot.isDragging,
                              "rule-is-editing": isEditing,
                              "rule-editing-is-disabled":
                                editingId !== null && !isEditing,
                            })}
                          >
                            {isEditing ? (
                              <div tw="w-full h-full flex flex-col">
                                <div tw="w-full h-full overflow-hidden">
                                  {ruleEditorValue && (
                                    <RuleEditor
                                      type={type}
                                      segmentGroupsEnabled={
                                        !!features.segment_groups
                                      }
                                      offerRuleGroupsEnabled={
                                        !!features.offer_rule_groups
                                      }
                                      platform={platform}
                                      segmentGroups={segmentGroups}
                                      segments={segments}
                                      inlineSegments={inlineSegments}
                                      properties={properties}
                                      flows={flows}
                                      flowTests={flowTests}
                                      eligibilityMessages={eligibilityMessages}
                                      hideHeading
                                      value={ruleEditorValue}
                                      undeletableMessages={undeletableMessageIds(
                                        rule.id
                                      )}
                                      onChange={(v) => {
                                        if (v.type !== "flow_route_rule") {
                                          throw new Error();
                                        }
                                        setRuleEditorValue(v);
                                      }}
                                      onClickCreateSegmentGroup={() =>
                                        onCreateSegmentGroup()
                                      }
                                      onClickCreateSegment={() =>
                                        onCreateSegment()
                                      }
                                      onClickCreateMessage={(id) => {
                                        onCreateMessage(id);
                                      }}
                                      onClickDeleteMessage={handleDeleteMessage}
                                    />
                                  )}
                                </div>
                                <div tw="flex items-center px-4 py-2 border-t border-divider">
                                  <div tw="ml-auto">
                                    <Button
                                      type="button"
                                      onClick={handleCancel}
                                      disabled={isSaving}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type="button"
                                      buttonType="primary"
                                      onClick={handleSave}
                                      disabled={
                                        isSaving ||
                                        (!ruleEditorValue?.flowIds.length &&
                                          !ruleEditorValue?.flowTestIds
                                            .length &&
                                          !ruleEditorValue
                                            ?.eligibilityMessageIds.length)
                                      }
                                      isLoading={isSaving}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <RuleBody>
                                <RuleDrag>
                                  <DragHandle
                                    {...provided.dragHandleProps}
                                    className={classNames({
                                      "is-disabled":
                                        !!editingId || rules.length === 1,
                                    })}
                                  >
                                    <FontAwesomeIcon icon={faGripVertical} />
                                  </DragHandle>
                                </RuleDrag>
                                <RuleColumns
                                  className="rule-columns"
                                  onClick={() => handleClickRule(rule.id)}
                                >
                                  <RuleColumn>
                                    <RuleSegments
                                      segmentGroups={rule.flow_route_rule_segment_groups
                                        .map((g) =>
                                          segmentGroups.find(
                                            (test) =>
                                              test.id === g.segment_group_id
                                          )
                                        )
                                        .filter(isPresent)}
                                      segments={rule.flow_route_rule_segments
                                        .map((s) =>
                                          segments.find(
                                            (test) => test.id === s.segment_id
                                          )
                                        )
                                        .filter(isPresent)}
                                      inlineSegments={rule.flow_route_rule_segments
                                        .map((s) =>
                                          inlineSegments.find(
                                            (test) => test.id === s.segment_id
                                          )
                                        )
                                        .filter(isPresent)}
                                      newConditions={newConditions[index] || []}
                                    />
                                  </RuleColumn>
                                  <RuleColumn>
                                    <BoxContainer>
                                      {flows.map((flow) => {
                                        const isOn =
                                          !!rule.flow_route_rule_flows.find(
                                            (f) => f.flow_id === flow.id
                                          );

                                        if (!isOn) {
                                          return null;
                                        }

                                        return (
                                          <FlowBox
                                            key={flow.id}
                                            isEditable={false}
                                            isOn={true}
                                          >
                                            {flow.title}
                                          </FlowBox>
                                        );
                                      })}
                                      {flowTests.map((flowTest) => {
                                        const isOn =
                                          !!rule.flow_route_rule_flow_tests.find(
                                            (f) =>
                                              f.flow_test_id === flowTest.id
                                          );

                                        if (!isOn) {
                                          return null;
                                        }

                                        return (
                                          <Tippy
                                            key={flowTest.id}
                                            content="A/B test"
                                          >
                                            <FlowTestBox
                                              isEditable={false}
                                              isOn={true}
                                            >
                                              <FontAwesomeIcon
                                                icon={faVial}
                                                transform="shrink-3"
                                              />{" "}
                                              {flowTest.name}
                                            </FlowTestBox>
                                          </Tippy>
                                        );
                                      })}
                                      {eligibilityMessages.map((message) => {
                                        const isOn =
                                          !!rule.flow_route_rule_eligibility_messages.find(
                                            (m) =>
                                              m.eligibility_message_id ===
                                              message.id
                                          );

                                        if (!isOn) {
                                          return null;
                                        }

                                        return (
                                          <EligibilityMessageBox
                                            key={message.id}
                                            isEditable={false}
                                            isOn={true}
                                          >
                                            {message.name}
                                          </EligibilityMessageBox>
                                        );
                                      })}
                                    </BoxContainer>
                                  </RuleColumn>
                                </RuleColumns>
                                <RuleDelete>
                                  {!editingId && (
                                    <button
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleDeleteRule(rule.id);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTimesCircle} />
                                    </button>
                                  )}
                                </RuleDelete>
                              </RuleBody>
                            )}
                          </RuleRow>
                        );
                      }}
                    </Draggable>
                  )
              )}
              {provided.placeholder}
            </Rules>
          )}
        </Droppable>
      </Card>
      <div
        tw="flex"
        className={classNames({
          "rule-is-editing": !!editingId,
        })}
      >
        <div
          tw="shrink"
          className={generateClassName(
            "Upgrade to create flow routing rules based on customer segments.",
            "panel"
          )}
        >
          <Button
            buttonType="alternate-secondary"
            size="sm"
            disabled={!!editingId}
            onClick={() => (!isFreeMode ? handleAddRule() : undefined)}
          >
            <FontAwesomeIcon icon={faPlus} transform="left-1" /> Add rule
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FlowRoutes;
