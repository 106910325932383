import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

export default function useModal(delay: number = 200) {
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    if (!isOpen) {
      window.setTimeout(() => {
        setKey(nanoid());
      }, delay);
    }
  }, [delay, isOpen]);

  return [isOpen, setIsOpen, key] as const;
}
