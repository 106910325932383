import { gql, useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import tw from "twin.macro";

import {
  CouponOfferFormGetCouponQuery,
  CouponOfferFormGetCouponQueryVariables,
  offer_goal_enum,
  OfferFormContainerCouponsQuery,
  platform_enum,
} from "../../../../__generated__/graphql";
import Currency from "../../../../common/Currency";
import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSetTitle from "../../../../common/form/FieldSetTitle";
import FieldStaticValue from "../../../../common/form/FieldStaticValue";
import FormMode from "../../../../common/form/FormMode";
import SelectInput from "../../../../common/form/input/SelectInput";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import HelpIcon from "../../../../common/HelpIcon";
import PillRadio from "../../../../common/PillRadio";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../../common/platform/features";
import usePaidFeature, {
  generatePaidFeatureClassName,
} from "../../../upgrade-account/usePaidFeature";
import CouponDropdown from "../../CouponDropdown";
import { CouponOfferFormValues } from "../types";

interface CouponDetailsFormPartialProps {
  mode: FormMode;
  goal?: offer_goal_enum;
  isSubmitting: boolean;
  coupons: OfferFormContainerCouponsQuery["platform_coupon"] | undefined;
  platform: platform_enum;
}

const CouponDetailsFormPartial: React.FunctionComponent<
  CouponDetailsFormPartialProps
> = ({
  mode,
  isSubmitting,
  coupons,
  platform,
  goal = offer_goal_enum.retention,
}) => {
  const { isFreeMode, setPaidFeatureRef } = usePaidFeature();

  const apollo = useApolloClient();

  const { register, watch, formState, control, setValue } =
    useTranslatableFormContext<CouponOfferFormValues>();

  const values = watch();

  const [couponMode, setCouponMode] = useState<string>("new");

  useEffect(() => {
    if (mode !== "create") {
      return;
    }

    if (
      !platformHasFeature(platform, PlatformFeature.CouponsNewCoupons, {
        defaultForCustom: true,
      })
    ) {
      setCouponMode("existing");
    }

    if (couponMode === "new") {
      setValue("couponId", null);
      setValue(
        "couponType",
        platformHasFeature(
          platform,
          PlatformFeature.CouponsPercentageDiscount,
          { defaultForCustom: true }
        )
          ? "percentage"
          : "fixed"
      );
      setValue("amountOff", null);
      setValue("duration", "once");
      setValue("months", null);
    } else {
      setValue("amountOff", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponMode, setValue]);

  const setCouponValues = useCallback(
    async (couponId: string) => {
      const result = await apollo.query<
        CouponOfferFormGetCouponQuery,
        CouponOfferFormGetCouponQueryVariables
      >({
        query: gql`
          query CouponOfferFormGetCouponQuery($platformId: String!) {
            platform_coupon(where: { platform_id: { _eq: $platformId } }) {
              platform_id
              type
              amount_off
              duration
              duration_interval
              duration_count
            }
          }
        `,
        variables: {
          platformId: couponId,
        },
      });

      const coupon = result.data.platform_coupon.length
        ? result.data.platform_coupon[0]
        : undefined;
      if (!coupon) {
        throw new Error(`Platform coupon not found: ${couponId}`);
      }

      setValue("couponType", coupon.type);
      setValue("amountOff", Number(coupon.amount_off));
      setValue("duration", coupon.duration);
      setValue("durationInterval", coupon.duration_interval);
      setValue("durationCount", coupon.duration_count);
      setValue("months", coupon.duration_count);
    },
    [apollo, setValue]
  );

  useEffect(() => {
    if (!values.couponId || mode === "edit") {
      return;
    }

    setCouponValues(values.couponId);
  }, [setCouponValues, values.couponId, mode]);

  return (
    <>
      <fieldset
        disabled={isSubmitting || mode === "edit"}
        ref={(ref) => setPaidFeatureRef(ref)}
      >
        <FieldSetTitle>Coupon details</FieldSetTitle>
        {mode === "create" && (
          <>
            {platformHasFeature(platform, PlatformFeature.CouponsNewCoupons) &&
              platformHasFeature(
                platform,
                PlatformFeature.CouponsExistingCoupons
              ) && (
                <PillRadio
                  value={couponMode}
                  options={[
                    {
                      label: "Create new coupon",
                      value: "new",
                    },
                    {
                      label: "Use existing coupon",
                      value: "existing",
                    },
                  ]}
                  onChange={(value) => setCouponMode(value)}
                  tw="mt-4 mb-2"
                />
              )}
            {couponMode === "existing" && (
              <FieldRow>
                <FieldLabel>
                  <label>Coupon</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="couponId"
                    render={({ field }) => (
                      <CouponDropdown
                        value={field.value}
                        onChange={field.onChange}
                        coupons={coupons}
                      />
                    )}
                  />
                </FieldInput>
              </FieldRow>
            )}
          </>
        )}
        {(couponMode !== "existing" || platform !== "custom") && (
          <>
            {platformHasFeature(
              platform,
              PlatformFeature.CouponsPercentageDiscount,
              { defaultForCustom: true }
            ) &&
              platformHasFeature(
                platform,
                PlatformFeature.CouponsFixedAmountDiscount,
                { defaultForCustom: true }
              ) && (
                <FieldRow
                  className={generatePaidFeatureClassName(
                    "Upgrade your plan to modify discount type.",
                    isFreeMode,
                    "form"
                  )}
                >
                  <FieldLabel>
                    <label htmlFor="couponType">Discount type</label>
                  </FieldLabel>
                  <FieldInput>
                    <SelectInput
                      {...register("couponType")}
                      id="couponType"
                      defaultValue="percentage"
                      css={couponMode !== "new" ? tw`hidden` : undefined}
                    >
                      <option value="percentage">Percentage discount</option>
                      <option value="fixed">Fixed amount discount</option>
                    </SelectInput>
                    {couponMode === "existing" && (
                      <FieldStaticValue>
                        {!values.couponId
                          ? "Select a coupon"
                          : values.couponType === "percentage"
                          ? "Percentage discount"
                          : values.couponType === "fixed"
                          ? "Fixed amount discount"
                          : "Select a coupon"}
                      </FieldStaticValue>
                    )}
                  </FieldInput>
                </FieldRow>
              )}
            <FieldRow
              className={generatePaidFeatureClassName(
                "Upgrade your plan to modify discount amount.",
                isFreeMode,
                "form"
              )}
            >
              <FieldLabel>
                <label>
                  {values.couponType === "percentage" ? (
                    <>Percentage off</>
                  ) : (
                    <>Discount amount</>
                  )}{" "}
                </label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("amountOff", {
                    validate: (value) =>
                      mode === "edit" ||
                      !!values.couponId ||
                      (value !== null &&
                        Number(value) > 0 &&
                        (values.couponType === "percentage"
                          ? value > 0 && value <= 100
                          : value > 0)),
                  })}
                  id="amountOff"
                  width="xs"
                  fieldError={formState.errors.amountOff}
                  css={couponMode !== "new" ? tw`hidden` : undefined}
                />
                {couponMode === "existing" && (
                  <FieldStaticValue>
                    {values.amountOff ? (
                      values.couponType === "percentage" ? (
                        <>{values.amountOff}%</>
                      ) : (
                        <Currency value={values.amountOff} />
                      )
                    ) : (
                      "Select a coupon"
                    )}
                  </FieldStaticValue>
                )}
              </FieldInput>
            </FieldRow>
            <FieldRow
              className={generatePaidFeatureClassName(
                "Upgrade your plan to modify discount duration.",
                isFreeMode,
                "form"
              )}
              css={
                !platformHasFeature(
                  platform,
                  PlatformFeature.CouponsForeverDuration,
                  { defaultForCustom: true }
                ) &&
                !platformHasFeature(
                  platform,
                  PlatformFeature.CouponsCustomDuration,
                  { defaultForCustom: true }
                )
                  ? tw`hidden`
                  : undefined
              }
            >
              <FieldLabel>
                <label htmlFor="duration">Duration</label>
              </FieldLabel>
              <FieldInput>
                <SelectInput
                  {...register("duration")}
                  id="duration"
                  defaultValue="once"
                  css={couponMode !== "new" ? tw`hidden` : undefined}
                >
                  <option value="once">Once</option>
                  <option value="forever">Forever</option>

                  {platformHasFeature(
                    platform,
                    PlatformFeature.CouponsCustomDuration,
                    { defaultForCustom: true }
                  ) && (
                    <option value="repeating">
                      Multiple{" "}
                      {platformHasFeature(
                        platform,
                        PlatformFeature.CouponsMonthsDuration,
                        { defaultForCustom: true }
                      )
                        ? "months"
                        : "billing cycles"}
                    </option>
                  )}
                </SelectInput>
                {couponMode === "existing" && (
                  <FieldStaticValue>
                    {!values.couponId
                      ? "Select a coupon"
                      : values.duration === "once"
                      ? "Once"
                      : values.duration === "forever"
                      ? "Forever"
                      : values.duration === "repeating"
                      ? `Multiple ${
                          platformHasFeature(
                            platform,
                            PlatformFeature.CouponsMonthsDuration,
                            { defaultForCustom: true }
                          )
                            ? `${values.durationInterval}s`
                            : "billing cycles"
                        }`
                      : "Select a coupon"}
                  </FieldStaticValue>
                )}
              </FieldInput>
            </FieldRow>
          </>
        )}
        {values.duration === "repeating" && (
          <FieldRow
            className={generatePaidFeatureClassName(
              "Upgrade your plan to modify discount duration options.",
              isFreeMode,
              "form"
            )}
          >
            <FieldLabel>
              <label htmlFor="months">
                {platformHasFeature(
                  platform,
                  PlatformFeature.CouponsMonthsDuration,
                  { defaultForCustom: true }
                ) ? (
                  couponMode === "existing" ? (
                    <span tw="capitalize">{`${values.durationInterval}s`}</span>
                  ) : (
                    "Months"
                  )
                ) : (
                  "Billing cycles"
                )}
              </label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("months", {
                  validate: (value) => value !== null && Number(value) > 0,
                })}
                id="months"
                width="xs"
                fieldError={formState.errors.months}
                css={couponMode !== "new" ? tw`hidden` : undefined}
              />
              {couponMode === "existing" && (
                <FieldStaticValue>
                  {values.months ? values.months : "Select a coupon"}
                </FieldStaticValue>
              )}
            </FieldInput>
          </FieldRow>
        )}
        {platform === "custom" && (
          <FieldRow>
            <FieldLabel>
              <label htmlFor="couponId">Coupon ID</label>
            </FieldLabel>
            <FieldInput>
              <TextInput {...register("couponId")} id="couponId" width="full" />
              <FieldHint>
                Optional coupon ID or code from your billing system.
              </FieldHint>
            </FieldInput>
          </FieldRow>
        )}
      </fieldset>

      <FieldRow
        css={
          !platformHasFeature(
            platform,
            PlatformFeature.CouponsApplyToSubscriber
          )
            ? tw`hidden`
            : undefined
        }
        className={generatePaidFeatureClassName(
          "Upgrade your plan to modify how coupons are applied.",
          isFreeMode,
          "form"
        )}
      >
        <FieldLabel>
          <label>Apply to</label>
        </FieldLabel>
        <FieldInput>
          <Controller
            control={control}
            name="applyTo"
            render={({ field }) => (
              <PillRadio
                value={field.value}
                options={[
                  {
                    label: "Subscription",
                    value: "subscription",
                    tooltip:
                      "Apply the coupon directly to the subscription (recommended)",
                    disabled: isFreeMode,
                  },
                  {
                    label: "Customer",
                    value: "subscriber",
                    tooltip:
                      "Apply the coupon to the customer so that it affects all of their subscriptions",
                    disabled: isFreeMode,
                  },
                ]}
                onChange={field.onChange}
              />
            )}
          />
        </FieldInput>
      </FieldRow>
      {platformHasFeature(
        platform,
        PlatformFeature.CouponsReplaceExistingCoupons
      ) && (
        <FieldRow
          className={generatePaidFeatureClassName(
            "By default, an offer will not be displayed to a subscriber if they already have an active coupon. Upgrade to allow your offers to replace existing coupons.",
            isFreeMode,
            "form"
          )}
        >
          <FieldLabel>
            <label>
              Replace coupons{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they already have an active coupon. This setting will allow this offer to replace any existing coupon." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowCouponOverride")}
              id="allowCouponOverride"
              type="checkbox"
              disabled={isFreeMode}
            />
            <label htmlFor="allowCouponOverride">Replace other coupons</label>
          </FieldCheckboxInput>
        </FieldRow>
      )}
      {goal === "conversion" &&
        platformHasFeature(platform, PlatformFeature.EndTrial) && (
          <FieldRow>
            <FieldLabel>
              <label>End trial</label>
            </FieldLabel>
            <FieldCheckboxInput>
              <input {...register("endTrial")} id="endTrial" type="checkbox" />
              <label htmlFor="endTrial">End trial when accepted</label>
            </FieldCheckboxInput>
          </FieldRow>
        )}
    </>
  );
};

export default CouponDetailsFormPartial;
