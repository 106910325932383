import tw, { styled } from "twin.macro";

const DeEpmphasizeColor = "#f9f9f9";

export const RuleHeader = styled.div`
  ${tw`flex border-b border-gray-200 rounded-t`}

  &.rule-is-editing {
    background: ${DeEpmphasizeColor};
  }
`;
