import classNames from "classnames";
import { styled } from "twin.macro";

const OfferOption = styled.div.attrs((props: { className?: string }) => ({
  className: classNames("flow-offer-option", props.className),
}))<{
  className: string;
}>``;

export default OfferOption;
