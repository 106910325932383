import classNames from "classnames";
import { styled } from "twin.macro";

const OfferOptionsFieldSet = styled.div.attrs(
  (props: { className?: string }) => ({
    className: classNames("flow-offer-options__field-set", props.className),
  })
)<{
  className: string;
}>``;

export default OfferOptionsFieldSet;
