import { Temporal } from "@js-temporal/polyfill";
import { UnreachableCaseError } from "ts-essentials";
import { isPresent } from "ts-is-present";

import {
  language_enum,
  offer_type_enum,
} from "../../../../__generated__/graphql";
import { OfferDetailsFragment } from "../../../../__generated__/graphql";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import translationText from "../../../../common/translationText";
import translationValue from "../../../../common/translationValue";
import { OfferFormValues } from "../types";
import mapFormValues from "./mapFormValues";

const getInitialFormValues = (
  offer: OfferDetailsFragment,
  enabledLanguages: language_enum[]
): TranslatedForms<OfferFormValues> => {
  const metadata = JSON.stringify(
    Object.entries(offer.metadata).map(([key, value]) => ({
      key,
      value,
    }))
  );

  const offerType: `${offer_type_enum}` = offer.type;

  switch (offerType) {
    case "coupon":
      if (!offer.offer_coupon) {
        throw new Error("Offer is missing coupon configuration");
      }

      return mapFormValues(
        {
          type: "coupon",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          couponType: offer.offer_coupon.type,
          amountOff: Number(offer.offer_coupon.amount_off),
          duration: offer.offer_coupon.duration,
          months: offer.offer_coupon.months,
          applyTo: offer.offer_coupon.apply_to,
          allowCouponOverride: offer.offer_coupon.override_active_coupon,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          metadata,
          couponId: offer.offer_coupon.platform_id,
          style: offer.style,
          countdownTimer: offer.offer_timer?.show_timer !== false,
          timerMode: offer.offer_timer?.offer_timer_mode || "",
          startTime: offer.offer_timer?.start_time
            ? Temporal.PlainDateTime.from(offer.offer_timer.start_time)
            : Temporal.Now.plainDateTimeISO(),
          endTime: offer.offer_timer?.end_time
            ? Temporal.PlainDateTime.from(offer.offer_timer.end_time)
            : Temporal.Now.plainDateTimeISO(),
          offerLength: offer.offer_timer?.duration || "24:00:00",
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    case "change_plan":
      if (
        !offer.offer_change_plan ||
        !offer.offer_change_plan.platform_plan_id
      ) {
        throw new Error("Offer is missing change plan configuration");
      }

      return mapFormValues(
        {
          type: "change_plan",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          planId: offer.offer_change_plan.platform_plan_id,
          planName: null,
          couponId: offer.offer_change_plan.offer_coupon_id?.toString(),
          prorate: offer.offer_change_plan.prorate,
          changeAt: offer.offer_change_plan.change_at,
          resetBillingDate: offer.offer_change_plan.reset_billing_date,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          style: offer.style,
          countdownTimer: offer.offer_timer?.show_timer !== false,
          timerMode: offer.offer_timer?.offer_timer_mode || "",
          startTime: offer.offer_timer?.start_time
            ? Temporal.Instant.from(offer.offer_timer.start_time)
                .toZonedDateTimeISO(Temporal.Now.timeZone())
                .toPlainDateTime()
            : Temporal.Now.plainDateTimeISO(),
          endTime: offer.offer_timer?.end_time
            ? Temporal.Instant.from(offer.offer_timer.end_time)
                .toZonedDateTimeISO(Temporal.Now.timeZone())
                .toPlainDateTime()
            : Temporal.Now.plainDateTimeISO(),
          offerLength: offer.offer_timer?.duration || "24:00:00",
          metadata,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    case "modify_subscription":
      if (!offer.offer_modify_subscription) {
        throw new Error("Offer is missing modify subscription configuration");
      }

      return mapFormValues(
        {
          type: "modify_subscription",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          style: offer.style,
          allowOverride: offer.override_active_offer,
          prorate: offer.offer_modify_subscription.prorate,
          modifyAt: offer.offer_modify_subscription.modify_at,
          addPlans: JSON.stringify(
            offer.offer_modify_subscription.offer_modify_subscription_options
              .filter((o) => o.type === "add_plan")
              .map((o) => o.platform_plan_id)
          ),
          removePlans: JSON.stringify(
            offer.offer_modify_subscription.offer_modify_subscription_options
              .filter((o) => o.type === "remove_plan")
              .map((o) => o.platform_plan_id)
          ),
          addAddons: JSON.stringify(
            offer.offer_modify_subscription.offer_modify_subscription_options
              .filter((o) => o.type === "add_addon")
              .map((o) => o.platform_addon_id)
          ),
          removeAddons: JSON.stringify(
            offer.offer_modify_subscription.offer_modify_subscription_options
              .filter((o) => o.type === "remove_addon")
              .map((o) => o.platform_addon_id)
          ),
          addCustomPlans: JSON.stringify(
            offer.offer_modify_subscription.offer_modify_subscription_options
              .filter((o) => o.type === "add_plan")
              .map((o) => o.custom_plan_id)
          ),
          removeCustomPlans: JSON.stringify(
            offer.offer_modify_subscription.offer_modify_subscription_options
              .filter((o) => o.type === "remove_plan")
              .map((o) => o.custom_plan_id)
          ),
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          metadata,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    case "trial_extension":
      if (!offer.offer_trial_extension) {
        throw new Error("Offer is missing trial extension configuration");
      }

      return mapFormValues(
        {
          type: "trial_extension",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          days: offer.offer_trial_extension.days,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          style: offer.style,
          countdownTimer: offer.offer_timer?.show_timer !== false,
          timerMode: offer.offer_timer?.offer_timer_mode || "",
          startTime: offer.offer_timer?.start_time
            ? Temporal.Instant.from(offer.offer_timer.start_time)
                .toZonedDateTimeISO(Temporal.Now.timeZone())
                .toPlainDateTime()
            : Temporal.Now.plainDateTimeISO(),
          endTime: offer.offer_timer?.end_time
            ? Temporal.Instant.from(offer.offer_timer.end_time)
                .toZonedDateTimeISO(Temporal.Now.timeZone())
                .toPlainDateTime()
            : Temporal.Now.plainDateTimeISO(),
          offerLength: offer.offer_timer?.duration || "24:00:00",
          metadata,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    case "pause_subscription":
      if (!offer.offer_pause_subscription) {
        throw new Error("Offer is missing pause subscription configuration");
      }

      const option1Metadata = JSON.stringify(
        Object.entries(
          offer.offer_pause_subscription.offer_pause_subscription_options[0]
            .metadata
        ).map(([key, value]) => ({
          key,
          value,
        }))
      );
      const option2Metadata = JSON.stringify(
        Object.entries(
          offer.offer_pause_subscription.offer_pause_subscription_options[1]
            ?.metadata || {}
        ).map(([key, value]) => ({
          key,
          value,
        }))
      );
      const option3Metadata = JSON.stringify(
        Object.entries(
          offer.offer_pause_subscription.offer_pause_subscription_options[2]
            ?.metadata || {}
        ).map(([key, value]) => ({
          key,
          value,
        }))
      );

      const formValues = mapFormValues(
        {
          type: "pause_subscription",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          style: offer.style,
          allowOverride: offer.override_active_offer,
          pauseAt: offer.offer_pause_subscription.pause_at,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          metadata,
          option1Present: true,
          option1Interval:
            offer.offer_pause_subscription.offer_pause_subscription_options[0]
              .type === "date"
              ? "date"
              : offer.offer_pause_subscription
                  .offer_pause_subscription_options[0].interval,
          option1IntervalCount:
            offer.offer_pause_subscription.offer_pause_subscription_options[0]
              .interval_count,
          option1Metadata,
          option2Present:
            offer.offer_pause_subscription.offer_pause_subscription_options
              .length > 1,
          option2Interval:
            offer.offer_pause_subscription.offer_pause_subscription_options[1]
              ?.type === "date"
              ? "date"
              : offer.offer_pause_subscription
                  .offer_pause_subscription_options[1]?.interval,
          option2IntervalCount:
            offer.offer_pause_subscription.offer_pause_subscription_options[1]
              ?.interval_count,
          option2Metadata,
          option3Present:
            offer.offer_pause_subscription.offer_pause_subscription_options
              .length > 2,
          option3Interval:
            offer.offer_pause_subscription.offer_pause_subscription_options[2]
              ?.type === "date"
              ? "date"
              : offer.offer_pause_subscription
                  .offer_pause_subscription_options[2]?.interval,
          option3IntervalCount:
            offer.offer_pause_subscription.offer_pause_subscription_options[2]
              ?.interval_count,
          option3Metadata,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

      for (const language of enabledLanguages) {
        const option1Text = translationValue(
          offer.offer_pause_subscription.offer_pause_subscription_options[0]
            .text_translation,
          language,
          language
        ).value;

        const option2Text = translationValue(
          offer.offer_pause_subscription.offer_pause_subscription_options[1]
            ?.text_translation,
          language,
          language
        )?.value;

        const option3Text = translationValue(
          offer.offer_pause_subscription.offer_pause_subscription_options[2]
            ?.text_translation,
          language,
          language
        )?.value;

        formValues[language] = {
          ...formValues[language],
          option1Text: option1Text ? JSON.stringify(option1Text) : null,
          option2Text: option2Text ? JSON.stringify(option2Text) : null,
          option3Text: option3Text ? JSON.stringify(option3Text) : null,
        } as any;
      }

      return formValues;

    case "upgrade":
      if (!offer.offer_upgrade || !offer.offer_upgrade.platform_plan_id) {
        throw new Error("Offer is missing upgrade configuration");
      }

      return mapFormValues(
        {
          type: "upgrade",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          planId: offer.offer_upgrade.platform_plan_id,
          planName: null,
          changeAt: offer.offer_upgrade.change_at,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          style: offer.style,
          metadata,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    case "downgrade":
      if (!offer.offer_downgrade || !offer.offer_downgrade.platform_plan_id) {
        throw new Error("Offer is missing downgrade configuration");
      }

      return mapFormValues(
        {
          type: "downgrade",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          planId: offer.offer_downgrade.platform_plan_id,
          planName: null,
          changeAt: offer.offer_downgrade.change_at,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          style: offer.style,
          metadata,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    case "product_swap":
      if (!offer.offer_product_swap) {
        throw new Error("Offer is missing product swap configuration");
      }

      return mapFormValues(
        {
          type: "product_swap",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          specificProducts: offer.offer_product_swap.specific_products,
          products:
            offer.offer_product_swap.offer_product_swap_platform_ecommerce_products.map(
              (p) => ({
                productId: p.platform_ecommerce_product_id,
                name: p.platform_ecommerce_product?.name || "",
                imageUrl: p.platform_ecommerce_product?.image_url || null,
                discountAmount:
                  p.platform_ecommerce_product
                    ?.platform_plan_ecommerce_products[0]?.discount_amount ||
                  "0",
                discountType:
                  p.platform_ecommerce_product
                    ?.platform_plan_ecommerce_products[0]?.discount_type ||
                  null,
                variants:
                  p.offer_product_swap_platform_ecommerce_product_platform_variants.map(
                    (v) => ({
                      variantId: v.platform_variant?.platform_id || "",
                      name: v.platform_variant?.name || "",
                      price: v.platform_variant?.price
                        ? Number(v.platform_variant.price)
                        : 0,
                      value: true,
                      options:
                        v.platform_variant?.platform_variant_options.map(
                          (o) => ({
                            key: o.key,
                            value: o.value,
                          })
                        ) || [],
                    })
                  ),
              })
            ),
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          metadata,
          style: offer.style,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    case "reschedule_order": {
      if (!offer.offer_reschedule_order) {
        throw new Error("Offer is missing reschedule order configuration");
      }

      const formValues = mapFormValues(
        {
          type: "reschedule_order",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          skipEnabled: offer.offer_reschedule_order.skip,
          rescheduleEnabled: offer.offer_reschedule_order.reschedule,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          metadata,
          style: offer.style,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

      for (const language of enabledLanguages) {
        const skipButtonText = translationText(
          offer.offer_reschedule_order.skip_button_text_translation,
          language,
          language
        );
        const rescheduleButtonText = translationText(
          offer.offer_reschedule_order.reschedule_button_text_translation,
          language,
          language
        );

        formValues[language] = {
          ...formValues[language],
          skipButtonText,
          rescheduleButtonText,
        } as any;
      }

      return formValues;
    }

    case "custom":
      if (!offer.offer_custom) {
        throw new Error("Offer is missing custom configuration");
      }

      return mapFormValues(
        {
          type: "custom",
          name: offer.name,
          tags: offer.offer_tags
            .map((t) => t.tag)
            .map((t) => t?.name)
            .filter(isPresent),
          allowOverride: offer.override_active_offer,
          showBanner: offer.show_banner,
          image: offer.image_url || "",
          style: offer.style,
          countdownTimer: offer.offer_timer?.show_timer !== false,
          timerMode: offer.offer_timer?.offer_timer_mode || "",
          startTime: offer.offer_timer?.start_time
            ? Temporal.Instant.from(offer.offer_timer.start_time)
                .toZonedDateTimeISO(Temporal.Now.timeZone())
                .toPlainDateTime()
            : Temporal.Now.plainDateTimeISO(),
          endTime: offer.offer_timer?.end_time
            ? Temporal.Instant.from(offer.offer_timer.end_time)
                .toZonedDateTimeISO(Temporal.Now.timeZone())
                .toPlainDateTime()
            : Temporal.Now.plainDateTimeISO(),
          offerLength: offer.offer_timer?.duration || "24:00:00",
          metadata,
          confirmationEnabled: offer.confirmation_enabled,
        },
        offer,
        enabledLanguages
      );

    default:
      throw new UnreachableCaseError(offerType);
  }
};

export default getInitialFormValues;
