import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import tw from "twin.macro";

import {
  language_enum,
  platform_enum,
} from "../../../../__generated__/graphql";
import Button from "../../../../common/form/Button";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import PillRadio from "../../../../common/PillRadio";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../../common/platform/features";
import LanguageRadio from "../../../flow/edit/LanguageRadio";
import { useUpsell } from "../../../upgrade-account/UpsellProvider";
import { generatePaidFeatureClassName } from "../../../upgrade-account/usePaidFeature";
import defaultInterval from "../../lib/defaultPauseInterval";
import { PauseSubscriptionOfferFormValues } from "../types";
import PauseSubscriptionOptionFormPartial from "./PauseSubscriptionOptionFormPartial";

interface PauseSubscriptionDetailsFormPartialProps {
  mode: FormMode;
  isSubmitting: boolean;
  platform: platform_enum;
  initialValues?: TranslatedForms<PauseSubscriptionOfferFormValues>;
  onChangeEditingLanguage: (value: language_enum) => void;
}

const PauseSubscriptionDetailsFormPartial: React.FunctionComponent<
  PauseSubscriptionDetailsFormPartialProps
> = ({
  mode,
  isSubmitting,
  platform,
  initialValues,
  onChangeEditingLanguage,
}) => {
  const {
    register,
    watch,
    control,
    setValue,
    editingLanguage,
    defaultLanguage,
    enabledLanguages,
    setEditingLanguage,
    formState,
  } = useTranslatableFormContext<PauseSubscriptionOfferFormValues>();

  const { enabled: isFreeMode } = useUpsell();

  const values = watch();

  let optionCount = 0;
  if (values.option1Present) {
    optionCount++;
  }
  if (values.option2Present) {
    optionCount++;
  }
  if (values.option3Present) {
    optionCount++;
  }

  let optionNumber = 0;
  const getNextOptionNumber = () => {
    optionNumber++;
    return optionNumber;
  };

  const handleChangeEditingLanguage = (value: language_enum) => {
    setEditingLanguage(value);
    onChangeEditingLanguage(value);
  };

  if (
    !platformHasFeature(
      platform,
      PlatformFeature.PauseSubscriptionSpecifyLength,
      { defaultForCustom: true }
    )
  ) {
    return null;
  }

  return (
    <>
      {(enabledLanguages.length > 1 || isFreeMode) && (
        <div
          className={generatePaidFeatureClassName(
            "Upgrade your plan to add support for multiple languages.",
            isFreeMode,
            "form"
          )}
        >
          <div tw="mt-10">
            <LanguageRadio
              value={editingLanguage}
              languages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              onChange={handleChangeEditingLanguage}
            />
          </div>
        </div>
      )}
      <FieldSet
        title="Pause details"
        disabled={isSubmitting || mode === "edit"}
      >
        <FieldRow
          css={
            !platformHasFeature(
              platform,
              PlatformFeature.PauseSubscriptionPauseAtEndOfPeriod
            )
              ? tw`hidden`
              : undefined
          }
        >
          <FieldLabel>
            <label>Pause at</label>
          </FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="pauseAt"
              render={({ field }) => (
                <PillRadio
                  value={field.value}
                  options={[
                    {
                      label: "Immediately",
                      value: "immediately",
                      tooltip: "Pause the subscription immediately",
                    },
                    {
                      label: "End of period",
                      value: "period_end",
                      tooltip:
                        "Pause the subscription at the end of the billing period",
                    },
                  ]}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        {values.option1Present && (
          <PauseSubscriptionOptionFormPartial
            mode={mode}
            optionIndex={1}
            optionCount={optionCount}
            optionNumber={getNextOptionNumber()}
            platform={platform}
            editingLanguage={editingLanguage}
            initialTextValue={
              initialValues?.[editingLanguage]?.option1Text || undefined
            }
            initialTextValueKey={`option-1-${editingLanguage}`}
            onDelete={() => {
              setValue("option1Present", false);
              setValue("option1Interval", defaultInterval(platform));
              setValue("option1IntervalCount", null);
              setValue("option1Text", null);
              setValue("option1Metadata", JSON.stringify([]));
            }}
            register={register}
            control={control}
            formState={formState}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        )}

        {values.option2Present && (
          <PauseSubscriptionOptionFormPartial
            mode={mode}
            optionIndex={2}
            optionCount={optionCount}
            optionNumber={getNextOptionNumber()}
            platform={platform}
            editingLanguage={editingLanguage}
            initialTextValue={
              initialValues?.[editingLanguage]?.option2Text || undefined
            }
            onDelete={() => {
              setValue("option2Present", false);
              setValue("option2Interval", defaultInterval(platform));
              setValue("option2IntervalCount", null);
              setValue("option2Text", null);
              setValue("option2Metadata", JSON.stringify([]));
            }}
            register={register}
            control={control}
            formState={formState}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        )}

        {values.option3Present && (
          <PauseSubscriptionOptionFormPartial
            mode={mode}
            optionIndex={3}
            optionCount={optionCount}
            optionNumber={getNextOptionNumber()}
            platform={platform}
            editingLanguage={editingLanguage}
            initialTextValue={
              initialValues?.[editingLanguage]?.option3Text || undefined
            }
            onDelete={() => {
              setValue("option3Present", false);
              setValue("option3Interval", defaultInterval(platform));
              setValue("option3IntervalCount", null);
              setValue("option3Text", null);
              setValue("option3Metadata", JSON.stringify([]));
            }}
            register={register}
            control={control}
            formState={formState}
            watch={watch}
            isSubmitting={isSubmitting}
          />
        )}

        {mode !== "edit" && (
          <Button
            tw="mt-3 ml-0"
            type="button"
            buttonType="alternate-secondary"
            size="sm"
            onClick={() => {
              if (!values.option1Present) {
                setValue("option1Present", true);
                return;
              }

              if (!values.option2Present) {
                setValue("option2Present", true);
                return;
              }

              if (!values.option3Present) {
                setValue("option3Present", true);
                return;
              }
            }}
            disabled={optionCount >= 3}
          >
            <FontAwesomeIcon icon={faPlus} /> Add option
          </Button>
        )}
      </FieldSet>
    </>
  );
};

export default PauseSubscriptionDetailsFormPartial;
