import { Controller } from "react-hook-form";
import tw from "twin.macro";

import EditorSelector from "../../../../common/editor2/EditorSelector";
import { isContentEmpty } from "../../../../common/editor2/lib";
import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldError from "../../../../common/form/FieldError";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldRowBlock from "../../../../common/form/FieldRowBlock";
import FieldSetTitle from "../../../../common/form/FieldSetTitle";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import { useUpsell } from "../../../upgrade-account/UpsellProvider";
import { CouponOfferFormValues } from "../types";

interface ConfirmationModalFormPartialProps {
  isSubmitting: boolean;
}

const ConfirmationModalFormPartial: React.FunctionComponent<
  ConfirmationModalFormPartialProps
> = ({ isSubmitting }) => {
  const { register, watch, formState, control, editingLanguage, forms } =
    useTranslatableFormContext<CouponOfferFormValues>();

  const values = watch();

  const { enabled: isFreeMode } = useUpsell();

  return (
    <fieldset disabled={isSubmitting}>
      <FieldSetTitle>Confirmation modal</FieldSetTitle>
      <FieldRow>
        <FieldLabel>
          <label>Enabled</label>
        </FieldLabel>
        <FieldCheckboxInput>
          <input
            {...register("confirmationEnabled")}
            id="confirmationEnabled"
            type="checkbox"
            disabled={isFreeMode}
          />
          <label htmlFor="confirmationEnabled">Show confirmation modal</label>
        </FieldCheckboxInput>
      </FieldRow>
      <fieldset css={!values.confirmationEnabled ? tw`hidden` : undefined}>
        <FieldRowBlock>
          <FieldLabel>
            <label>Content</label>
          </FieldLabel>
          <Controller
            control={control}
            name="confirmationContent"
            render={({ field }) => {
              const form = forms[editingLanguage];
              if (!form) {
                return <></>;
              }

              return (
                <>
                  <EditorSelector
                    key={editingLanguage}
                    initialValueKey={editingLanguage}
                    value={form.confirmationContent}
                    format={form.contentFormat}
                    isOfferContent={true}
                    height="10rem"
                    tagsEnabled={false}
                    linksEnabled={false}
                    listsEnabled={false}
                    imagesEnabled={false}
                    videosEnabled={false}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    fieldError={formState.errors.confirmationContent}
                  />
                  <FieldError error={formState.errors.confirmationContent} />
                </>
              );
            }}
            rules={{
              validate: (value) =>
                values.confirmationEnabled
                  ? !!value && !isContentEmpty(JSON.parse(value))
                    ? true
                    : "Content is required."
                  : true,
            }}
          />
        </FieldRowBlock>
        <FieldRow>
          <FieldLabel>
            <label>Confirm button text</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("confirmationConfirmButtonText", {
                validate: (value) =>
                  values.confirmationEnabled
                    ? !!value
                      ? true
                      : "Confirm button text is required."
                    : true,
              })}
              width="md"
              fieldError={formState.errors.confirmationConfirmButtonText}
              disabled={isFreeMode}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>Cancel button text</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("confirmationCancelButtonText", {
                validate: (value) =>
                  values.confirmationEnabled
                    ? !!value
                      ? true
                      : "Cancel button text is required."
                    : true,
              })}
              width="md"
              fieldError={formState.errors.confirmationCancelButtonText}
              disabled={isFreeMode}
            />
          </FieldInput>
        </FieldRow>
      </fieldset>
    </fieldset>
  );
};

export default ConfirmationModalFormPartial;
