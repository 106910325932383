import classNames from "classnames";
import { styled } from "twin.macro";

const OfferOptionInput = styled.div.attrs((props: { className?: string }) => ({
  className: classNames("flow-offer-option__input", props.className),
}))<{
  className: string;
}>``;

export default OfferOptionInput;
