import classNames from "classnames";
import { styled } from "twin.macro";

const OfferOptionInputItem = styled.button.attrs(
  (props: { className?: string }) => ({
    className: classNames(
      "flow-offer-option__input-item",
      "flow-offer-option__input-item--button",
      props.className
    ),
  })
)<{
  className: string;
}>``;

export default OfferOptionInputItem;
