import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import { RescheduleOrderOfferFormValues } from "../types";

interface RescheduleOrderDetailsFormPartialProps {
  mode: FormMode;
  isSubmitting: boolean;
}

const RescheduleOrderDetailsFormPartial: React.FunctionComponent<
  RescheduleOrderDetailsFormPartialProps
> = ({ mode, isSubmitting }) => {
  const { register } =
    useTranslatableFormContext<RescheduleOrderOfferFormValues>();

  return (
    <FieldSet
      title="Reschedule details"
      disabled={isSubmitting || mode === "edit"}
    >
      <FieldRow tw="hidden">
        <FieldLabel>
          <label>Skip</label>
        </FieldLabel>
        <FieldCheckboxInput>
          <input
            {...register("skipEnabled")}
            id="skipEnabled"
            type="checkbox"
          />
          <label htmlFor="rescheduleEnabled">Allow skip next order</label>
        </FieldCheckboxInput>
      </FieldRow>
      <FieldRow>
        <FieldLabel>
          <label>Reschedule</label>
        </FieldLabel>
        <FieldCheckboxInput>
          <input
            {...register("rescheduleEnabled")}
            id="rescheduleEnabled"
            type="checkbox"
          />
          <label htmlFor="rescheduleEnabled">Allow order rescheduling</label>
        </FieldCheckboxInput>
      </FieldRow>
    </FieldSet>
  );
};

export default RescheduleOrderDetailsFormPartial;
