import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPresent } from "ts-is-present";

import { FlowOfferRulesQuery } from "../../../../__generated__/graphql";
import { psSegmentGroup } from "../../../../common/icons/customIcons";
import { AnyBox, BoxContainer } from "../../../../common/rules/RuleBoxes";
import { SegmentBox } from "../../../flow/FlowRoutesPanel";
import { useFlowVersion } from "../FlowVersionProvider";
import OfferRuleOffers from "./OfferRuleOffers";

interface ReadOnlyOfferRulesProps {
  offerRuleGroupId: number;
  offerRules: FlowOfferRulesQuery;
}

const ReadOnlyOfferRules: React.FunctionComponent<ReadOnlyOfferRulesProps> = ({
  offerRuleGroupId,
  offerRules,
}) => {
  const { getObjectVersion } = useFlowVersion();

  const offerRuleGroup = offerRules.offer_rule_group.find(
    (group) => group.id === offerRuleGroupId
  );
  if (!offerRuleGroup) {
    throw new Error("Missing offer rule group");
  }

  const groupVersion = getObjectVersion(offerRuleGroup);

  const rules = groupVersion.offer_rule_group_version_offer_rules
    .map((rule) => rule.offer_rule)
    .filter(isPresent);

  return (
    <div tw="flex flex-col w-full mt-4">
      <div tw="flex w-full text-gray-600 font-semibold border-b border-divider pb-1">
        <div tw="w-1/2 px-1">Segment</div>
        <div tw="w-1/2 px-1">Offer</div>
      </div>

      {!rules.length && <div tw="pl-1 py-1 text-type-light">No rules</div>}

      {rules.map((rule) => {
        const ruleVersion = getObjectVersion(rule);
        const ruleRules = ruleVersion.offer_rule_version_offer_rule_rules
          .map((r) => r.offer_rule_rule)
          .filter(isPresent)
          .map((r) => ({
            ...r,
            weight: r.weight,
            offerIds: r.offer_ids,
            offerTestIds: r.offer_test_ids,
            offerGroupIds: r.offer_group_ids,
            offerAutopilotOfferIds: r.offer_autopilot_offer_ids,
            includePresentNoOffer: r.include_present_no_offer,
            deflectionIds: [],
          }));

        return (
          <div key={rule.id} tw="flex w-full border-b py-2">
            <div tw="w-1/2">
              {!ruleVersion.offer_rule_version_segments.length &&
                !ruleVersion.offer_rule_version_segment_groups && (
                  <BoxContainer>
                    <AnyBox isOn={true} readOnly>
                      Any
                    </AnyBox>
                  </BoxContainer>
                )}
              {ruleVersion.offer_rule_version_segments.map(({ segment }) => {
                if (!segment) {
                  throw new Error("Missing segment");
                }
                return (
                  <BoxContainer>
                    <SegmentBox isOn={true} isEditable={false} key={segment.id}>
                      {segment.name}
                    </SegmentBox>
                  </BoxContainer>
                );
              })}
              {ruleVersion.offer_rule_version_segment_groups.map(
                ({ segment_group }) => {
                  if (!segment_group) {
                    throw new Error("Missing segment group");
                  }
                  return (
                    <SegmentBox
                      isOn={true}
                      isEditable={false}
                      key={segment_group.id}
                    >
                      <FontAwesomeIcon
                        icon={psSegmentGroup}
                        color="white"
                        transform="shrink-4 left-3 down-1"
                      />
                      {segment_group.name}
                    </SegmentBox>
                  );
                }
              )}
            </div>
            <div tw="w-1/2">
              {ruleRules.map((r, ruleIndex) => (
                <OfferRuleOffers
                  rule={r}
                  offers={r.offer_rule_rule_offers
                    .map((r) => r.offer)
                    .filter(isPresent)}
                  offerGroups={r.offer_rule_rule_offer_groups
                    .map((r) => r.offer_group)
                    .filter(isPresent)}
                  offerTests={r.offer_rule_rule_offer_tests
                    .map((r) => r.offer_test)
                    .filter(isPresent)}
                  offerAutopilotOffers={r.offer_rule_rule_offer_autopilot_offers
                    .map((r) => r.offer_autopilot?.offer)
                    .filter(isPresent)}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReadOnlyOfferRules;
